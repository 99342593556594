import React, { useMemo, useState } from 'react'
import _ from "lodash";
import dayjs from 'dayjs';

//Components
import Header from "../header";
import MainInfoAndImage from "../main-info-and-image";
import LihatDetailProperty from "../lihat-detail-property";
import Price from "../price";
import Deskripsi from "../deskripsi";
import SpesifikasiProperty from "../spesifikasi-property";
import KondisiProperty from "../kondisi-property";
import FasilitasProperty from "../fasilitas-properti";
import FasilitasUmum from "../fasilitas-umum";
import LokasiProperti from "../lokasi-properti";
import MarketingAgent from "../marketing-agent";
import Footer from "../footer";
import ModalLeads from "../modal-leads";

const Main = ({ data, images, location }) => {
  const [isOpenModalFormLead, setIsOpenModalFormLead] = useState(false)

  const getValueFromData = (str = "") => _.get(data, str)
  const getValueFromlocation = (str = "") => _.get(location, str)

  //Helpers
  const handleTanggal = (date = "", format) => {
    if (!date) return "";
    let result = dayjs(date).format(format)

    if (result == "Invalid Date") {
      const newDate = date.replace(/-/g, '/')
      result = dayjs(newDate).format(format)
    }

    return result
  }

  const messageAgent = () => setIsOpenModalFormLead(true)

  //Mapping payloads
  const payloadJudul = useMemo(() => {
    return {
      id: getValueFromData("property.id"),
      category: getValueFromData("property.property_category.name"),
      type: getValueFromData("property.property_type.name"),
      furnitureCondition: getValueFromData("property.furniture_condition"),
      title: getValueFromData("property.title"),
      location: `${getValueFromlocation("area.name")}, ${getValueFromlocation("city.name")}, ${getValueFromlocation("province.name")}`,
      position: {
        lat: +getValueFromlocation("latitude"),
        lng: +getValueFromlocation("longitude")
      },
      street: getValueFromlocation("street"),
      createdAt: handleTanggal(getValueFromData("property.created_at"), "DD/MM/YYYY"),
      updatedAt: handleTanggal(getValueFromData("property.updated_at"), "DD/MM/YYYY, HH:MM A"),
    }
  }, [data])

  const payloadPriceAndDate = useMemo(() => {
    return {
      price: getValueFromData("property.price"),
      unitPrice: getValueFromData("property.unit_price.name"),
      type: getValueFromData("property.property_type.name"),
      isArchived: getValueFromData("property.is_archived"),
      minRent: getValueFromData("property.min_rent"),
      hargaTurun: getValueFromData("property.summary.historical_price")
    }
  }, [data])


  const payloadSpesifikasiRumah = useMemo(() => {
    return {
      floors: getValueFromData("property.number_of_floors"),
      bedroom: getValueFromData("property.bedroom"),
      additionalBedroom: getValueFromData("property.additional_bedroom"),
      bathroom: getValueFromData("property.bathroom"),
      additionalBathroom: getValueFromData("property.additional_bathroom"),

      luasBangunan: getValueFromData("property.building_area"),
      lebarBangunan: getValueFromData("property.building_width"),
      panjangBangunan: getValueFromData("property.building_length"),

      luasTanah: getValueFromData("property.surface_area"),
      lebarTanah: getValueFromData("property.land_width"),
      panjangTanah: getValueFromData("property.land_length"),

      garageCap: getValueFromData("property.garage_capacity"),
      parkCap: getValueFromData("property.parking_capacity"),

      electricity: getValueFromData("property.electrical_power")
    }
  }, [data])

  const payloadKondisiRumah = useMemo(() => {
    return {
      tahunDibangun: getValueFromData("property.year"),
      sertifikat: getValueFromData("property.ownership_status.name"),
      menghadap: getValueFromData("property.face"),
      kondisi: getValueFromData("property.property_condition"),
    }
  }, [data])

  const payloadFasilitasRumah = useMemo(() => {
    return {
      telepon: getValueFromData("property.number_of_phone_lines"),
      tamanPribadi: _.find(getValueFromData("property.facilities"), { name: "Taman" }),
      kolam: _.find(getValueFromData("property.facilities"), { name: "Kolam Renang" }),
      AC: _.find(getValueFromData("property.facilities"), { name: "AC" }),
      CCTV: _.find(getValueFromData("property.facilities"), { name: "CCTV" })
    }
  }, [data])

  const payloadFasilitasPerumahan = useMemo(() => {
    return {
      keamanan: _.find(getValueFromData("property.environment_facilities"), { name: "Keamanan 24 Jam" }),
      taman: _.find(getValueFromData("property.environment_facilities"), { name: "Taman" }),
      track: _.find(getValueFromData("property.environment_facilities"), { name: "Jogging Track" }),
      masjid: _.find(getValueFromData("property.environment_facilities"), { name: "Masjid" }),
      CCTV: _.find(getValueFromData("property.environment_facilities"), { name: "CCTV" }),
      kolam: _.find(getValueFromData("property.environment_facilities"), { name: "Kolam Renang" }),
    }
  }, [data])

  const payloadLokasi = useMemo(() => {
    return {
      location: `${getValueFromlocation("area.name")}, ${getValueFromlocation("city.name")}, ${getValueFromlocation("province.name")}`,
      position: {
        lat: +getValueFromlocation("latitude"),
        lng: +getValueFromlocation("longitude")
      },
      street: getValueFromlocation("street"),
    }
  }, [data])

  const payloadMarketingAgent = useMemo(() => {
    return {
      name: getValueFromData("user.full_name"),
      phone: getValueFromData("user.phone"),
      company: getValueFromData("user.company.name"),
      careerLevel: getValueFromData("user.career_level.name"),
      userImage: getValueFromData("user.user_image.online_profile.url"),
      id: getValueFromData("user.id"),
    }
  }, [data])

  return (
    <div id='detail-listing-2-0-dekstop-wrapper'>
      <div className='detail-listing-2-0-content-wrapper'>
        <Header />
        <MainInfoAndImage video={data?.video?.url || data?.property?.video?.url || ""} messageAgent={messageAgent} mainInfoData={payloadJudul} images={images} />
        {/* <LihatDetailProperty /> */}
        <Price data={payloadPriceAndDate} />
        <Deskripsi description={getValueFromData("property.description")} />
        <SpesifikasiProperty category={payloadJudul.category} data={payloadSpesifikasiRumah} />
        <KondisiProperty data={payloadKondisiRumah} />
        <FasilitasProperty data={payloadFasilitasRumah} />
        <FasilitasUmum data={payloadFasilitasPerumahan} />
        <LokasiProperti data={payloadLokasi} />
        <MarketingAgent messageAgent={messageAgent} data={payloadMarketingAgent} />
      </div>
      <Footer />

      <ModalLeads 
        full_name={data?.user?.full_name} 
        phone_number={data?.user?.phone} 
        onClose={() => setIsOpenModalFormLead(false)} 
        isOpen={isOpenModalFormLead} 
        property_id={payloadJudul.id}
        user_id={data?.user?.id}
        slug={data?.slug ?? data?.property?.slug}
      />
    </div>
  )
}

export default Main