import React from 'react'

import GreenCheck from "../../../../assets/svgs/green-check.svg";

function FasilitasUmum({ data }) {
  const { keamanan, taman, track, masjid, CCTV, kolam } = data

  let counter = React.useMemo(() => {
    let count = 0;
    keamanan && (count += 1)
    taman && (count += 1) 
    track && (count += 1) 
    masjid && (count += 1) 
    CCTV && (count += 1) 
    kolam && (count += 1)

    return count;
  }, [keamanan, taman, track, masjid, CCTV, kolam])

  if (!keamanan && !taman && !track && !masjid && !CCTV && !kolam) return null;
  return (
    <div className='fasilitas-umum-dekstop'>
      <p className='header-fasilitas-umum'>Fasilitas umum</p>

      <div className={`header-fasilitas-umum ${counter == 1 ? "no-underline" : ""} `}>
        {keamanan ? <div className='section-fasilitas-umum'>
          <span>Keamanan 24 jam</span>
          <GreenCheck className="green-check" />
        </div> : null}
        {taman ? <div className='section-fasilitas-umum'>
          <span>Taman</span>
          <GreenCheck className="green-check" />
        </div> : null}
        {track ? <div className='section-fasilitas-umum'>
          <span>Jogging track</span>
          <GreenCheck className="green-check" />
        </div> : null}
        {masjid ? <div className='section-fasilitas-umum'>
          <span>Tempat ibadah</span>
          <GreenCheck className="green-check" />
        </div> : null}
        {CCTV ? <div className='section-fasilitas-umum'>
          <span>CCTV</span>
          <GreenCheck className="green-check" />
        </div> : null}
        {kolam ? <div className='section-fasilitas-umum'>
          <span>Kolam renang umum</span>
          <GreenCheck className="green-check" />
        </div> : null}
      </div>
    </div>
  )
}

export default FasilitasUmum