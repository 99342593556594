import React from 'react'

import Info from "assets/svgs/info-black.svg";
import Question from "assets/svgs/question.svg";

function HubungiMA({ messageAgent }) {

  return (
    <div className='hubungi-ma'>
      <div
        onClick={messageAgent}
        className='button-hubungi-ma'
      >
        <Question /> <span>Info lebih lanjut</span>
      </div>
      <div className='wrapper-logo-info'>
        <Info className="logo-info" />
        <span>Dengan memilih tanya listing, Anda akan kami arahkan ke aplikasi WhatsApp untuk terhubung dengan marketing dari properti ini.</span>
      </div>
    </div>
  )
}

export default HubungiMA