import Lottie from "lottie-react";
import animationData from 'assets/lottie/loading.json'

import Style from "./content-wrapper.module.scss"
import SuccessIcon from "@assets/svgs/checklist-v2.svg"

const ContentWrapper = ({ igType, id, isSuccess, isLoading, children }) => {

  const handleRedirectDeeplink = () => {
    const url = `https://one21.page.link/?link=https://www.century21.com/shareig/${igType}/${id}&apn=com.one21&isi=1540220733&ibi=com.one21`
    window.location.href = url;
  }

  const RenderContent = () => {
    if (isLoading) {
      return (
        <div className={Style["loader"]}>
          <Lottie animationData={animationData} loop={true} />
          <p className={Style["title"]}>Sabar ya.. Kami sedang menghias foto listingmu biar terlihat luar biasa di Instagram.</p>
        </div>
      )
    }

    if (isSuccess) {
      return (
        <div className={Style["success"]}>
          <div className={Style["top"]}>
            <SuccessIcon className={Style["success-icon"]} />
            <p className={Style["success-title"]}>Hore🎉 Foto listingmu sudah siap untuk dipromosikan.</p>
            <p className={Style["success-subtitle"]}>Tap button di bawah untuk melanjutkan.</p>
            <button onClick={handleRedirectDeeplink} className={Style["promo"]}>Promosikan Listing</button>
          </div>
        </div>
      )
    }
  }
  return (
    <div className={Style["wrapper"]}>
      {RenderContent()}
      <div className={Style["content"]}>
        {children}
      </div>
    </div>
  )
}

export default ContentWrapper