import React, { useMemo } from 'react'

function SpesifikasiRumah({ data, category }) {
  const {
    floors,
    bedroom,
    additionalBedroom,
    bathroom,
    additionalBathroom,

    luasBangunan,
    lebarBangunan,
    panjangBangunan,

    luasTanah,
    lebarTanah,
    panjangTanah,

    garageCap,
    parkCap,

    electricity
  } = data;

  /**RENDER COMPONENT */
  const SectionOne = useMemo(() => {
    if (!+floors && (!+bedroom && +bedroom !== 0) && !+bathroom) return null;

    const RenderBedroom = () => {
      if (+bedroom === 0 && category?.toLowerCase() === "apartment") {
        return <div className='field-spesifikasi'><span>Kamar tidur</span> <span>Studio</span></div>
      }
      if (!!(+bedroom)) {
        return <div className='field-spesifikasi'><span>Kamar tidur</span> <span>{bedroom}{`${+additionalBedroom ? " +" + additionalBedroom : ""}`}</span></div>
      }
      return null
    }

    return (
      <div className='section-spesifikasi-rumah'>
        {+floors ? <div className='field-spesifikasi'><span>Jumlah lantai</span> <span>{floors}</span></div> : null}
        {RenderBedroom()}
        {+bathroom ? <div className='field-spesifikasi'><span>Kamar mandi</span> <span>{bathroom}{`${+additionalBathroom ? " +" + additionalBathroom : ""}`}</span></div> : null}
      </div>
    )
  }, [floors, bedroom, additionalBedroom, bathroom, additionalBathroom])

  const SectionTwo = useMemo(() => {
    if (!+luasBangunan && !+lebarBangunan && !+panjangBangunan) return null;

    return (
      <div className='section-spesifikasi-rumah'>
        {+luasBangunan ? <div className='field-spesifikasi'><span>Luas bangunan</span> <span>{luasBangunan} <span className='satuan'>m<sup>2</sup></span></span></div> : null}
        {+lebarBangunan ? <div className='field-spesifikasi'><span>Lebar bangunan</span> <span>{lebarBangunan} <span className='satuan'>m</span></span></div> : null}
        {+panjangBangunan ? <div className='field-spesifikasi'><span>Panjang bangunan</span> <span>{panjangBangunan} <span className='satuan'>m</span></span></div> : null}
      </div>
    )
  }, [luasBangunan, lebarBangunan, panjangBangunan])

  const SectionThree = useMemo(() => {
    if (!+luasTanah && !+lebarTanah && !+panjangTanah) return null;

    return (
      <div className='section-spesifikasi-rumah'>
        {+luasTanah ? <div className='field-spesifikasi'><span>Luas tanah</span> <span>{luasTanah} <span className='satuan'>m<sup>2</sup></span></span></div> : null}
        {+lebarTanah ? <div className='field-spesifikasi'><span>Lebar tanah</span> <span>{lebarTanah} <span className='satuan'></span></span></div> : null}
        {+panjangTanah ? <div className='field-spesifikasi'><span>Panjang tanah</span> <span>{panjangTanah} <span className='satuan'></span></span></div> : null}
      </div>
    )
  }, [luasTanah, lebarTanah, panjangTanah])

  const SectionFour = useMemo(() => {
    if (!+garageCap && !+parkCap) return null;

    return (
      <div className='section-spesifikasi-rumah'>
        {+garageCap ? <div className='field-spesifikasi'><span>Kapasitas garasi</span> <span>{garageCap} <span className='satuan'>mobil</span></span></div> : null}
        {+parkCap ? <div className='field-spesifikasi'><span>Carport</span> <span>{parkCap} <span className='satuan'>mobil</span></span></div> : null}
      </div>
    )
  }, [garageCap, parkCap])

  const SectionFive = useMemo(() => {
    if (!+electricity) return null;

    return (
      <div className='section-spesifikasi-rumah'>
        {+electricity ? <div className='field-spesifikasi'><span>Daya listrik</span> <span>{electricity} <span className='satuan'>watt</span></span></div> : null}
      </div>
    )
  }, [electricity])


  /**MAIN COMPONENT */
  if (!SectionOne && !SectionTwo && !SectionThree && !SectionFour && !SectionFive) return null;
  return (
    <div className='spesifikasi-rumah'>
      <p className='header-spesifikasi-rumah'>Spesifikasi Properti</p>
      {SectionOne}
      {SectionTwo}
      {SectionThree}
      {SectionFour}
      {SectionFive}
    </div>
  )
}

export default SpesifikasiRumah