import React from 'react'

//Components
import C21Logo from "../../../../assets/svgs/c21-logo-black.svg";

function Header() {
  return (
    <div className='dekstop-detail-listing-header'>
      <C21Logo className="logo" />
    </div>
  )
}

export default Header