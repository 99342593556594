import React, { useState, useEffect } from "react";
import { useRouter } from "next/router";

import Styles from "./new-profile.module.scss";

// Assets
import InfoBlue from "@assets/svgs/info-blue.svg";
import Monitor from "@assets/svgs/monitor.svg";
import { result } from "lodash";

// API
import { getWaaStatus } from '@services/marketing-profile';

const isBrowser = () => typeof window !== "undefined";

const Dot = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="8"
    height="8"
    viewBox="0 0 8 8"
    fill="none"
  >
    <path
      d="M7.5 4C7.5 4.32625 7.26531 4.57094 7.05813 4.78687C6.94031 4.91 6.81844 5.03687 6.7725 5.14844C6.73 5.25063 6.7275 5.42 6.725 5.58406C6.72031 5.88906 6.71531 6.23469 6.475 6.475C6.23469 6.71531 5.88906 6.72031 5.58406 6.725C5.42 6.7275 5.25063 6.73 5.14844 6.7725C5.03687 6.81844 4.91 6.94031 4.78687 7.05813C4.57094 7.26531 4.32625 7.5 4 7.5C3.67375 7.5 3.42906 7.26531 3.21312 7.05813C3.09 6.94031 2.96312 6.81844 2.85156 6.7725C2.74938 6.73 2.58 6.7275 2.41594 6.725C2.11094 6.72031 1.76531 6.71531 1.525 6.475C1.28469 6.23469 1.27969 5.88906 1.275 5.58406C1.2725 5.42 1.27 5.25063 1.2275 5.14844C1.18156 5.03687 1.05969 4.91 0.941875 4.78687C0.734687 4.57094 0.5 4.32625 0.5 4C0.5 3.67375 0.734687 3.42906 0.941875 3.21312C1.05969 3.09 1.18156 2.96312 1.2275 2.85156C1.27 2.74938 1.2725 2.58 1.275 2.41594C1.27969 2.11094 1.28469 1.76531 1.525 1.525C1.76531 1.28469 2.11094 1.27969 2.41594 1.275C2.58 1.2725 2.74938 1.27 2.85156 1.2275C2.96312 1.18156 3.09 1.05969 3.21312 0.941875C3.42906 0.734687 3.67375 0.5 4 0.5C4.32625 0.5 4.57094 0.734687 4.78687 0.941875C4.91 1.05969 5.03687 1.18156 5.14844 1.2275C5.25063 1.27 5.42 1.2725 5.58406 1.275C5.88906 1.27969 6.23469 1.28469 6.475 1.525C6.71531 1.76531 6.72031 2.11094 6.725 2.41594C6.7275 2.58 6.73 2.74938 6.7725 2.85156C6.81844 2.96312 6.94031 3.09 7.05813 3.21312C7.26531 3.42906 7.5 3.67375 7.5 4Z"
      fill="#232323"
    />
  </svg>
);

const ListingDanTransaksi = ({ amount, label, description }) => (
  <div className={Styles["listing-dan-transaksi"]}>
    <div className={Styles["amount-and-label"]}>
      <p>
        <span>{amount}</span> {label}
      </p>
    </div>
    <p className={Styles["description"]}>{description}</p>
  </div>
);

const ListingDanTransaksiMobile = ({ amount, label, description, type }) => (
  <div className={`${Styles["listing-dan-transaksi-mobile"]} ${Styles[type]}`}>
    <div className={Styles["amount-and-label"]}>
      <p>
        <span>{amount}</span> {label}
      </p>
    </div>
    <p className={Styles["description"]}>{description}</p>
  </div>
);

const ModalBagde = ({ isOpen, setIsShowModalActive }) => {
  const handleSayaMengerti = () => {
    localStorage.setItem('is_open_modal_active', true);
    setIsShowModalActive(false)
  }
  if (!isOpen) return null;

  return (
    <div className={Styles["modal-wrapper"]}>
      <div className={Styles["modal-content"]}>
        <div className={Styles["active-marketer-wrapper"]}>
          <span className={Styles["active-marketer"]}><Monitor /> Active Digital Marketer</span>
        </div>
        <p className={Styles["description"]}>Marketing ini aktif dalam pemasaran digital, dan telah diverifikasi oleh CENTURY 21 Indonesia.</p>
        <button onClick={handleSayaMengerti}>Saya Mengerti</button>
      </div>
    </div>
  )
}

const NewProfile = ({ 
  data = {}, 
  scrollToRef, 
  windowWidth, 
  transaksiBerhasil, 
  listing,
  messageAgent
 }) => {
  const router = useRouter();

  const [isShowModalActiveBadge, setIsShowModalActiveBadge] = useState(false)
  const [isShowModalActive, setIsShowModalActive] = useState(false)

  const profileName = React.useMemo(() => {
    if (data.name) {
      const name = data?.name?.split(" ") ?? "";
      const restName = name.slice(2, name.length);
      const singkatan = restName.map((item) => `${item[0]}.`).join(" ") || "";
      return `${name[0] ? name[0] : ""} ${name[1] ? name[1] : ""} ${singkatan || ""
        }`;
    }
    return "";
  }, [data.name]);

  const getKelipatan = (amount = 0, kelipatan) => {
    if (!amount || amount < kelipatan) return result

    if (amount % kelipatan === 0) {
      return amount;
    } else {
      return Math.floor(amount / kelipatan) * kelipatan + "+";
    }
  }

  useEffect(() => {
    async function handleGetWaa() {
      try {
        const res = await getWaaStatus(router?.query?.id);

        const storedData = localStorage.getItem('is_open_modal_active');

        if (res?.data?.status) {

          setIsShowModalActiveBadge(true)
          if (!storedData) {
            setIsShowModalActive(true)
          }
        }
      } catch (error) {
        setIsShowModalActive(false)
        setIsShowModalActiveBadge(false)
      }
    }

    handleGetWaa()
  }, [])

  if (windowWidth <= 1023) {
    return (
      <div className={Styles["mobile-wrapper"]}>
        <div className={Styles["content"]}>
          {!!isShowModalActiveBadge && <div className={Styles["active-marketer-wrapper"]}>
            <p className={Styles["active-marketer"]}><Monitor /> Active Digital Marketer</p>
          </div>}
          <p className={Styles["name"]}>{data?.name ?? ""}</p>
          <p className={Styles["level"]}>{data?.careerLevel}</p>
          <p className={Styles["company"]}>Century 21 {data?.office}</p>

          <div className={Styles["layer-profile"]}>
            <img className={Styles["profile-pic"]} src={data?.newPhoto} alt={data?.name ?? ""} />
            <button onClick={isBrowser() && messageAgent}>Hubungi Saya</button>
            <div className={Styles["info-hub-wa"]}>
              <InfoBlue />
              <p>
                Anda akan diarahkan untuk menghubungi Marketing via WhatsApp.
              </p>
            </div>

            {!!(transaksiBerhasil >= 25) && <ListingDanTransaksiMobile
              amount={getKelipatan(transaksiBerhasil, 25)}
              label={windowWidth >= 1920 ? "Transaksi Berhasil" : "Berhasil"}
              description={`Lebih dari ${getKelipatan(transaksiBerhasil, 25)} listing berhasil diselesaikan.`}
            />}

            {!!(listing >= 50) && <ListingDanTransaksiMobile
              amount={getKelipatan(listing, 50)}
              label="Listing"
              description={`Memiliki lebih dari ${getKelipatan(listing, 50)} listing yang bisa menjadi pilihan.`}
            />}
          </div>
        </div>
        <div className={Styles["background"]}>

        </div>
        <ModalBagde isOpen={isShowModalActive} setIsShowModalActive={setIsShowModalActive} />
      </div>
    )
  }

  return (
    <div className={Styles["wrapper"]}>
      <div className={Styles["profile"]}>
        <div className={Styles["left"]}>
          {!!isShowModalActiveBadge && <div className={Styles["active-marketer-wrapper"]}>
            <span className={Styles["active-marketer"]}><Monitor /> Active Digital Marketer</span>
          </div>}
          <p className={Styles["name"]}>{data?.name ?? ""}</p>
          <div className={Styles["level-and-company"]}>
            <span className={Styles["level"]}>{data?.careerLevel}</span>
            <Dot />
            <span className={Styles["company"]}>Century 21 {data?.office}</span>
          </div>

          <div className={Styles["wrapper-hubungi-saya"]}>
            <button onClick={isBrowser() && messageAgent}>Hubungi Saya</button>
            <div className={Styles["info-hub-wa"]}>
              <InfoBlue />
              <p>
                Anda akan diarahkan untuk menghubungi Marketing via WhatsApp.
              </p>
            </div>
          </div>

          <div className={Styles["wrapper-listing-and-container"]}>
            {!!(transaksiBerhasil >= 25) && <ListingDanTransaksi
              amount={getKelipatan(transaksiBerhasil, 25)}
              label={windowWidth >= 1920 ? "Transaksi Berhasil" : "Berhasil"}
              description={`Lebih dari ${getKelipatan(transaksiBerhasil, 25)} listing berhasil diselesaikan.`}
            />}

            {!!(listing >= 50) && <ListingDanTransaksi
              amount={getKelipatan(listing, 50)}
              label="Listing"
              description={`Memiliki lebih dari ${getKelipatan(listing, 50)} listing yang bisa menjadi pilihan.`}
            />}
          </div>
        </div>
        <div className={Styles["right"]}>
          <img src={data?.newPhoto} alt={data?.name ?? ""} />

          <button onClick={scrollToRef} className={Styles["scroll-to-listing"]}>
            <img
              src={"https://s3.ap-southeast-1.amazonaws.com/production.storages/storages/store/online-profile/ArrowDown.svg"}
              alt="." className={Styles["arrow-down"]}
            /> <span>Scroll untuk melihat lebih banyak</span>
          </button>
        </div>
      </div>

      <ModalBagde isOpen={isShowModalActive} setIsShowModalActive={setIsShowModalActive} />
    </div>
  );
};

export default NewProfile;
