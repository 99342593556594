import { fetch, download } from '@config/api';

export function getProfile(id) {
  const url = `module/marketing/users/${id}`;
  return fetch(url);
}

export function getListings(query = "") {
  const url = `module/marketing/properties${query}`;
  return fetch(url);
}

export function getListingDetails(slug) {
  const url = `module/marketing/properties/${slug}`;
  return fetch(url);
}

export function getListingsKantor(id) {
  const url = `module/property/offices`;
  return fetch(url);
}

export function getListingSold(id) {
  const url = `module/marketing/users/${id}/properties?kind=sold`;
  return fetch(url);
}

export function getAchievement(id) {
   
  const url = `module/marketing/users/${id}/achievements`;
  return fetch(url);
}

export function getWaaStatus(id) {
   
  const url = `/users/${id}/waa-status`;
  return fetch(url);
}
