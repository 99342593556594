import React, { useState } from 'react'
import ReactPannellum, { toggleFullscreen } from 'react-pannellum'
import Head from 'next/head'
import HeaderContent from './Header'
import Carousel from './Carousel'
import ListingDetail from './ListingDetail'
import ListingInfo from './ListingInfo'
import Ionicon from 'react-ionicons'
import { Badge } from 'reactstrap'

export default function index(props) {
  const { images, data, location } = props
  const [stateSlide, setStateSlide] = useState({
    current: 1,
    total: images.length
  })
  const [modalImage, setModalImage] = useState(false)
  const [selectedItem, setSelectedItem] = useState(0)
  const [modalPanorama, setModalPanorama] = useState(false)
  const [panoramaImage, setPanoramaImage] = useState('')

  let price = data
    ? data.property.price
        .toString()
        .split('')
        .reverse()
        .join('')
        .match(/\d{1,3}/g)
        .join('.')
        .split('')
        .reverse()
        .join('')
    : ``
  let currency = data
    ? data.property.currency.name === `IDR`
      ? `Rp`
      : `$`
    : ``
  let metaAgen = ` | Agen: ${data?.user?.full_name}`
  let metaDesc = data
    ? `${data?.property?.property_type?.name} ${data?.property?.property_category?.name} ${currency}${price} di ${location?.city?.name}${metaAgen}`
    : `One21: Asisten Virtual Untuk Agen Properti`

  function onClickItem(index) {
    setModalImage(!modalImage)
    setSelectedItem(index)
  }

  function onStateImage(num) {
    setStateSlide({
      current: num + 1,
      total: images.length
    })
  }

  function showPanorama() {
    const isPanorama = images
      .filter((val) => val.is_360)
      .findIndex((val, index) => stateSlide.current - 1 === index)
    return isPanorama > -1 ? false : true
  }

  function viewPanorama() {
    const isPanorama = images.find(
      (val, index) => stateSlide.current - 1 === index
    )
    if (isPanorama) {
      setPanoramaImage(isPanorama.image.cors)
      setModalPanorama(!modalPanorama)
      setModalImage(!modalImage)
    }
  }

  function onClosePanorama() {
    setModalPanorama(!modalPanorama)
    setModalImage(!modalImage)
    setPanoramaImage('')
    showPanorama()
  }

  return (
    <div>
      <Head>
        <title>{`${data ? data.property.title : `Listing`} | One21`}</title>
        <meta property="og:description" content={metaDesc} />
        <meta
          property="og:image"
          content={
            data
              ? data?.property?.summary?.property_images?.image?.thumbnail?.url ? data?.property?.summary?.property_images?.image?.thumbnail?.url : `https://s3.ap-southeast-1.amazonaws.com/production.assets.one21/assets/logo/icon-90efdb05b35f99324bb28b2d9827c138c2574cc8fafbeec6e37fdeea501fb6e6.png`
              : `https://s3.ap-southeast-1.amazonaws.com/production.assets.one21/assets/logo/icon-90efdb05b35f99324bb28b2d9827c138c2574cc8fafbeec6e37fdeea501fb6e6.png`
          }
        />
        <meta
          property="og:title"
          content={`${data ? data.property.title : `Listing`} | One21`}
        />
      </Head>
      <div className="row no-gutters justify-content-center">
        <div className="col-12 col-sm-7 col-md-5 col-lg-4 col-xl-4">
            {data && <ListingInfo data={data} />}
            {data && <ListingInfo data={data} />}
            {data && <ListingInfo data={data} />}
            <div className="modal-banner">
            
              {images.length > 0 && <div className="modal-header">
                <div></div>
                <div>
                  {stateSlide.current} / {stateSlide.total}
                </div>
                <div />
              </div>}
              <div className="modal-body">
                <Carousel
                  images={images}
                  selectedItem={selectedItem}
                  stateSlide={stateSlide}
                  onStateImage={onStateImage}
                  onOpenModal={() => {}}
                />
              </div>
            </div>

        </div>
      </div>
    </div>
  )
}
