import React from "react";
import _ from "lodash";

// Styles
import Style from "./transaksi-berhasil.module.scss";

//Assets
import PinRed from "@assets/svgs/pin-red.svg";
import Dot from "@assets/svgs/dot-grey.svg";

const EmptyImage =
  "https://s3.ap-southeast-1.amazonaws.com/production.storages/storages/store/property-attachments/original/Empty.png";

const ArrowRight = "https://s3.ap-southeast-1.amazonaws.com/production.storages/storages/store/online-profile/ArrowRight.svg";
const ArrowRightDisabled = "https://s3.ap-southeast-1.amazonaws.com/production.storages/storages/store/online-profile/ArrowRight-disabled.svg";

const ArrowLeft = "https://s3.ap-southeast-1.amazonaws.com/production.storages/storages/store/online-profile/ArrowLeft.svg"
const ArrowLeftDisabled = "https://s3.ap-southeast-1.amazonaws.com/production.storages/storages/store/online-profile/ArrowLeft-disabled.svg"

const TransaksiBerhasil = ({ data, name }) => {
  const [active, setActive] = React.useState(0);
  const currentData = React.useMemo(() => data[active] || {}, [data, active]);
  const isApartment = currentData?.category?.toLowerCase() === "apartment";

  function detectString(input) {
    const regexHari = /\bhari\b/gi;
    const regexMinggu = /\bminggu\b/gi;
    const regexBulan = /\bbulan\b/gi;
    const regexTahun = /\btahun\b/gi;
    const regexM2 = /\bm2\b/gi;

    if (regexHari.test(input)) {
      return '/Hari';
    } else if (regexMinggu.test(input)) {
      return '/Minggu';
    } else if (regexBulan.test(input)) {
      return '/Bulan';
    } else if (regexTahun.test(input)) {
      return '/Tahun';
    } else if (regexM2.test(input)) {
      return '/M2';
    } else {
      return null; // or any other value or message if none of the patterns match
    }
  }

  const RenderBedroom = () => {
    if (isApartment && +currentData?.bedroom === 0) {
      return (
        <span>Tipe <span className={Style["black"]}>Studio</span></span>
      )
    }

    if (!!(_.toNumber(currentData?.bedroom))) {
      return (
        <>
          <span>
            Kamar Tidur:{" "}
            <span className={Style["black"]}>{currentData?.bedroom}</span>
            {!!currentData?.additional_bedroom &&
              `${(<span>+</span>)}${(
                <span className={Style["black"]}>
                  {currentData?.additional_bedroom}
                </span>
              )}`}
          </span>
          <Dot />
        </>
      )
    }

    return null
  }

  return (
    <div className={Style["wrapper"]}>
      <div className={Style["content"]}>
        <div className={Style["header"]}>
          <div className={Style["title-subtitle"]}>
            <p className={Style["title"]}>Transaksi Berhasil</p>
            <p className={Style["subtitle"]}>
              Beberapa properti yang berhasil diselesaikan oleh{" "}
              <span>{name}</span>
            </p>
          </div>
          <div className={Style["button-slider-wrapper"]}>
            <button disabled={(active === 0 || !data?.length)} onClick={() => active > 0 && setActive((val) => val - 1)}>
              <img
                src={(active === 0 || !data?.length) ? ArrowLeftDisabled : ArrowLeft}
                className={`${Style["arrow-left"]} ${Style["arrow"]}`}
              />
            </button>
            <button
              disabled={(active === (data.length - 1) || !data?.length)}
              onClick={() =>
                active < data.length && setActive((val) => val + 1)
              }
            >
              <img
                src={(active === (data.length - 1) || !data?.length) ? ArrowRightDisabled : ArrowRight}
                className={`${Style["arrow-right"]} ${Style["arrow"]}`}
              />
            </button>
          </div>
        </div>

        <div className={Style["current-listing"]}>
          <div className={Style["info"]}>
            <p className={Style["location"]}>
              <PinRed /> {currentData?.area}, {currentData?.city}
            </p>

            <p className={Style["title"]}>
              {currentData?.title}
            </p>
            <div className={Style["details"]}>
              {RenderBedroom()}
              {!!(_.toNumber(currentData?.bathroom)) && (<>
                <span>
                  Kamar Mandi:{" "}
                  <span className={Style["black"]}>{currentData?.bathroom}</span>
                  {!!currentData?.additional_bathroom &&
                    `${(<span>+</span>)}${(
                      <span className={Style["black"]}>
                        {currentData.additional_bathroom}
                      </span>
                    )}`}
                </span>
                <Dot />
              </>)}
              {!!(_.toNumber(currentData?.building_area)) && <span>
                Luas Bangunan:{" "}
                <span className={Style["black"]}>
                  {currentData?.building_area} m<sup>2</sup>
                </span>
              </span>}
            </div>

            <p className={Style["nilai-transaksi"]}>Nilai Transaksi:</p>
            <p className={Style["price"]}>
              Rp{currentData?.soldPrice} <span>{detectString(currentData?.unitPriceName)}</span>
            </p>
          </div>
          <div className={Style["wrapper-image"]}>
            <img
              src={currentData?.image || EmptyImage}
              alt={currentData?.title}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default TransaksiBerhasil;
