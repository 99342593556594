import React, { useEffect, useMemo, useRef, useState } from 'react'
import { toPng } from 'html-to-image';

import Style from "./download-leaderboard.module.scss"

import useDownloadLeaderboard from './useDownloadLeaderboard';
import Spinner from '@components/reuseables/Spinner';

const DownloadLeaderboardImage = ({
  periode,
  career_level,
  company_name,
  region,
  user_image_url,
  user_name,
  user_web_url,
  bgC21,
  bgPattern,
  bgColor,
  c21,
  century21,
  rank,
  rankPrefix,
  styles,
  grain,
  id,
  periodeImage
}) => {
  const htmlElementRef = useRef(null);

  const [imageDataUrl, setImageDataUrl] = useState(null);
  const [previewImage, setPreviewImage] = useState(null);

  const {
    cardRef,
    convertToPdf,
    isConverting,
    generateQRCode,
    qrCodeUrl,
  } = useDownloadLeaderboard();

  const handleConvertToImage = () => {
    toPng(htmlElementRef.current, { includeQueryParams: true })
      .then(function (dataUrl) {
        setImageDataUrl(dataUrl);
      })
      .catch(function (error) {
        console.error('Error converting HTML to image:', error);
      });
  };


  useEffect(() => {
    if (cardRef.current && !!qrCodeUrl) {
      convertToPdf(id, user_name.split(" ").join("-").toLowerCase(), (data) => {
        setPreviewImage(data)
      })
    }
  }, [cardRef, qrCodeUrl])

  useEffect(() => {
    generateQRCode(user_web_url)
    !!htmlElementRef.current && handleConvertToImage()
  }, [])

  return (
    <div className={Style["wrapper"]}>
      {!!previewImage ?

        <img className={Style["preview-image"]} src={previewImage} alt={previewImage} />
        :
        (
          <>
            <Spinner />
            <div className={Style["content-wrapper"]}>
              <div ref={cardRef} style={{ background: bgColor }} className={Style["content"]}>
                {!!imageDataUrl && <img src={imageDataUrl} alt="adsasd" />}

                {/* LAYER 1 */}
                <img src={bgC21} alt='Background logo' className={Style["bg-logo"]} />

                {/* LAYER 2 */}
                <img src={bgPattern} alt='Background pattern' className={Style["bg-pattern"]} />

                {/* LAYER 3 */}
                <div className={`${Style["rank"]}`}>
                  <p style={{ color: styles.rank }} className={Style["top"]}>{rankPrefix}</p>
                  <p style={{ color: styles.rank }} className={Style["rank-number"]}>{rank}</p>
                </div>

                <p style={{ color: styles.periodePrefix }} className={Style["text-period"]}>PERIODE</p>
                <img className={Style["period-img"]} src={periodeImage} alt='periode img' />

                <p style={{ color: styles.regionPrefix }} className={`${Style["career-level"]} ${Style[+rank >= 5 ? "top-rank" : ""]}`}>{career_level} <br />PRODUKTIF <span style={{ color: styles.region }} className={Style["scale"]}>{region}</span></p>

                <div className={Style["box-qr"]}>
                  <img className={Style["qr"]} src={qrCodeUrl} alt='QR Code' />
                </div>

                <p style={{ color: styles.qrTitle }} className={Style["scan-qr"]}>Scan QR Code</p>
                <p style={{ color: styles.qrInfo }} className={Style["qr-info"]}>Temukan info properti lengkap di Website Pribadi Saya.</p>

                <p style={{ color: styles.name, background: styles.nameBg }} className={Style["user-name"]}>{user_name}</p>
                <img src={century21} alt='Century 21' className={Style["century-21"]} />
                <p style={{ color: styles.company }} className={Style["company-name"]}>{company_name}</p>

                <img src={c21} alt='C21 Seal' className={Style["c-21"]} />

                {/* LAYER 4 */}
                <img src={grain} alt='Grain' className={Style["grain"]} />

                {/* LAYER 5 */}
                <img src={user_image_url} alt='Profile Image' className={Style["profile-image"]} />
              </div>
            </div>
          </>
        )
      }

    </div>
  )
}

export default DownloadLeaderboardImage;
