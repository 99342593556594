import React from 'react'

import GreenCheck from "../../../../assets/svgs/green-check.svg";

function FasilitasRumah({ data }) {
  const { tamanPribadi, telepon, kolam, AC, CCTV } = data

  const fasilitas = React.useMemo(() => {
    let result = []

    if (parseInt(telepon)) result.push({ name: "Line telepon" })
    if (tamanPribadi) result.push({ name: "Taman pribadi" })
    if (AC) result.push({ name: "AC" })
    if (kolam) result.push({ name: "Kolam renang pribadi" })
    if (CCTV) result.push({ name: "CCTV" })

    return result
  }, [data])

  if (!fasilitas?.length) return null;
  return (
    <div className='fasilitas-rumah'>
      <p className='fasilitas-rumah-title'>Fasilitas rumah</p>
      <div className='wrapper-section'>
        {fasilitas.map((item, index) => {
          return (
            <div key={index} className='section-fasilitas-rumah'>
              <span>{item.name}</span>
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default FasilitasRumah