import React from 'react'

//Components
import MainInfo from './main-info/MainInfo';
import Image from './image/Image';

function index({ mainInfoData, images, messageAgent, video }) {
  return (
    <div className='main-info-and-image'>
      <MainInfo messageAgent={messageAgent} data={mainInfoData} />
      <Image video={video} images={images} />
    </div>
  )
}

export default index