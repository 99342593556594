import { useMemo } from 'react'
import { useRouter } from 'next/router'

// Hooks
import useImageOrientation from 'hooks/useImageOrientation';

// Components
import Square from './_components/square'
import Portrait from './_components/portrait'
import Landscape from './_components/landscape'

const IMAGE_SQUARE = "https://images.pexels.com/photos/7255396/pexels-photo-7255396.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
const IMAGE_LANDSCAPE = "https://images.pexels.com/photos/106399/pexels-photo-106399.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
const IMAGE_PORTRAIT = "https://images.pexels.com/photos/210538/pexels-photo-210538.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"

const DownloadInstagramFeed = () => {
  const query = useRouter().query;

  const IMAGE_URL = query.imageUrl
  const { imageOrientation, ORIENTATION, imgSize } = useImageOrientation(IMAGE_URL);

  const data = useMemo(() => ({
    imgSize: imgSize,
    imageUrl: IMAGE_URL,
    propertyCategory: "RUMAH",
    propertyType: "DIJUAL", // DIJUAL, DISEWA
    area: "SERPONG",
    price: "1,9M", // Format suffix: T, M, Juta 
    surfaceArea: "72",
    buildingArea: "140",
    bedroom: "4",
    additionalBedroom: "1",
    bathroom: "3",
    additionalBathroom: "1",
    id: "123",
    rentType: "Tahun" // Tahun, Bulan, Minggu, Hari
  }), [])

  const mappedQuery = useMemo(() => {
    let newQuery = {
      ...query,
      surfaceArea: +query.surfaceArea,
      buildingArea: +query.buildingArea,
      bedroom: +query.bedroom,
      additionalBedroom: +query.additionalBedroom,
      bathroom: +query.bathroom,
      additionalBathroom: +query.additionalBathroom,
    }
    
    query.rentType = query?.unitPrice ?? "Unit";

    return newQuery
  }, [query])

  if (imageOrientation === ORIENTATION.landscape) return <Landscape imgSize={imgSize}  {...mappedQuery} />
  if (imageOrientation === ORIENTATION.portrait) return <Portrait imgSize={imgSize}  {...mappedQuery} />
  if (imageOrientation === ORIENTATION.square) return <Square imgSize={imgSize}  {...mappedQuery} />

  return null
}

export default DownloadInstagramFeed