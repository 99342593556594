import React from 'react'

import capitalizeFirstLetter from '@helpers/capitalizeFirstLetter';

function MarketingAgent({ data }) {
  const { name, company, userImage, id } = data
  return (
    <div className='marketing-agent-card-wrapper'>
      <div className='info-image-wrapper'>
        <div className='marketing-agent-info'>
          <p className='header-marketing-agent'>Marketing</p>
          <a href={`/${capitalizeFirstLetter(name.split(" ").join("-"))}?id=${id}`} className='name'>{name}</a>
          <p className='company'>Century 21 {company}</p>
        </div>

        <div className='marketing-agent-image'>
          <img className='image' src={userImage} alt="foto marketing agent" />
        </div>
      </div>
    </div>
  )
}

export default MarketingAgent