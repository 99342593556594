
import React, { useMemo, useState, useCallback } from 'react'
import { GoogleMap, useLoadScript, Marker, InfoWindow } from "@react-google-maps/api";

export default function GoogleMapWrapper({ position, street, isOpenGoogleMapOnClick }) {
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: "AIzaSyAV06y2yu3mJx4tEBcGMisY3kxxOgg4aU4",
  });

  if (!isLoaded) return <div>Loading...</div>;
  return <Map position={position} street={street} isOpenGoogleMapOnClick={isOpenGoogleMapOnClick} />;
}

function Map({ position, street, isOpenGoogleMapOnClick }) {
  const handleClick = () => {
    const url = `https://www.google.com/maps?q=${position.lat},${position.lng}`;
    window.open(url, '_blank');
  }
  return (
    <GoogleMap
      id="circles-example"
      mapContainerStyle={{
        height: "100%",
        width: "100%"
      }}
      zoom={18}
      center={position}
      smoothCenter={position}
      onClick={isOpenGoogleMapOnClick && handleClick}
      options={{
        mapTypeControl: false,
        streetViewControl: false,
      }}
    >
      <Marker
        position={position}
        draggable={true}
      />
      <InfoWindow
        onClose={(err) => console.log(err)}
        position={{
          lat: position.lat + 0.0002,
          lng: position.lng || 0,
        }}
      >
        <div>
          <span style={{ padding: 0, margin: 0 }}>
            {`${street}`}
            <strong style={{ fontWeight: 800 }}> (Titik Lokasi)</strong>
          </span>
        </div>
      </InfoWindow>
    </GoogleMap>
  );
}