import React from 'react'

import Question from "assets/svgs/question.svg";
import Info from "assets/svgs/info-black.svg";

import capitalizeFirstLetter from '@helpers/capitalizeFirstLetter';

const isBrowser = () => typeof window !== 'undefined';
function MarketingAgent({ data, messageAgent }) {
  const { name, company, userImage, careerLevel, id } = data;

  return (
    <div className='dekstop-marketing-agent'>

      <div className='marketing-info-wrapper'>
        <div className='header'>
          <span>Marketing</span>
        </div>

        <a href={`/${capitalizeFirstLetter(name.split(" ").join("-"))}?id=${id}`} className='name'>{name}</a>
        <div className='title'>
          <p className='level'>{careerLevel}</p>
          <svg xmlns="http://www.w3.org/2000/svg" width="8" height="9" viewBox="0 0 8 9" fill="none">
            <path d="M7.5 4.15918C7.5 4.48543 7.26531 4.73012 7.05813 4.94605C6.94031 5.06918 6.81844 5.19605 6.7725 5.30762C6.73 5.4098 6.7275 5.57918 6.725 5.74324C6.72031 6.04824 6.71531 6.39387 6.475 6.63418C6.23469 6.87449 5.88906 6.87949 5.58406 6.88418C5.42 6.88668 5.25063 6.88918 5.14844 6.93168C5.03687 6.97762 4.91 7.09949 4.78687 7.2173C4.57094 7.42449 4.32625 7.65918 4 7.65918C3.67375 7.65918 3.42906 7.42449 3.21312 7.2173C3.09 7.09949 2.96312 6.97762 2.85156 6.93168C2.74938 6.88918 2.58 6.88668 2.41594 6.88418C2.11094 6.87949 1.76531 6.87449 1.525 6.63418C1.28469 6.39387 1.27969 6.04824 1.275 5.74324C1.2725 5.57918 1.27 5.4098 1.2275 5.30762C1.18156 5.19605 1.05969 5.06918 0.941875 4.94605C0.734687 4.73012 0.5 4.48543 0.5 4.15918C0.5 3.83293 0.734687 3.58824 0.941875 3.3723C1.05969 3.24918 1.18156 3.1223 1.2275 3.01074C1.27 2.90855 1.2725 2.73918 1.275 2.57512C1.27969 2.27012 1.28469 1.92449 1.525 1.68418C1.76531 1.44387 2.11094 1.43887 2.41594 1.43418C2.58 1.43168 2.74938 1.42918 2.85156 1.38668C2.96312 1.34074 3.09 1.21887 3.21312 1.10105C3.42906 0.893867 3.67375 0.65918 4 0.65918C4.32625 0.65918 4.57094 0.893867 4.78687 1.10105C4.91 1.21887 5.03687 1.34074 5.14844 1.38668C5.25063 1.42918 5.42 1.43168 5.58406 1.43418C5.88906 1.43887 6.23469 1.44387 6.475 1.68418C6.71531 1.92449 6.72031 2.27012 6.725 2.57512C6.7275 2.73918 6.73 2.90855 6.7725 3.01074C6.81844 3.1223 6.94031 3.24918 7.05813 3.3723C7.26531 3.58824 7.5 3.83293 7.5 4.15918Z" fill="#232323" />
          </svg>
          <p className='company'>Century 21 {company}</p>
        </div>

        <div className='phone-wrapper'>
          <div
            className='cbtn-whatsapp'
            onClick={isBrowser && messageAgent}>
            <Question className="logo-wa" />
            <span>Info lebih lanjut</span>
          </div>

          <div className='wrapper-logo-info'>
            <Info className="logo-info" />
            <span>Dengan memilih tanya listing, Anda akan kami arahkan ke aplikasi WhatsApp untuk terhubung dengan marketing dari properti ini.</span>
          </div>
        </div>
      </div>

      <div className='wrapper-ma-image'>
        <img className='ma-image' src={userImage} alt="..." />
      </div>
    </div>
  )
}

export default MarketingAgent