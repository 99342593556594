import React from 'react'

import CarretDown from '@assets/svgs/green-carret-down.svg'

function PriceAndDate({ data }) {
  const { createdAt, updatedAt, price = 0, unitPrice, minRent, type, hargaTurun } = data

  const minSewa = React.useMemo(() => {
    if (!minRent) return null;

    const intRent = parseInt(minRent)

    return intRent < 30 ? `${intRent} hari` : `${Math.round(intRent / 30)} bulan`;
  }, [minRent])

  return (
    <div className='price-and-date'>
      <div className='date-wrapper'>
        <div className='date-field'><span>Dipost</span> <span className='date'>{createdAt}</span></div>
        <div className='date-field'><span>Data diperbarui pada</span> <span className='date'>{updatedAt}</span></div>
        {/* <div className='date-field'><span>Telah dibagikan</span> <span className='date'></span></div> */}
      </div>

      <div className='price-wrapper'>
        <div className='price-price'>
          <span className='text-harga'>Harga {type !== "Dijual" ? "sewa" : ""}</span>
          <div className='price-box'>
            {data.isArchived ? <span className='price'>{type === "Dijual" ? "TERJUAL" : "TERSEWA"}</span>
              : <div className='price-box-inner'>
                <span className='text-rp'>Rp <span className='price'>&zwj;{price.toLocaleString("id-ID")}</span> <CarretDown /></span>
              </div>}
            {!!(hargaTurun?.status === "down") && !data.isArchived && <div className='price-box-inner'><span className='text-rp-down'>Rp <s className='price-down'>&zwj;{(+hargaTurun.from).toLocaleString("id-ID")}</s></span></div>}
          </div>
        </div>

        <div className='unit-price'><span>Unit harga</span> <span className='unit'>{unitPrice}</span></div>
        {!!(type !== "Dijual") && <div className='minimum-sewa'><span>Minimum Sewa <span className='required'>*</span></span> <span className='unit'>{minSewa}</span></div>}
      </div>
    </div>
  )
}

export default PriceAndDate