import React from "react";

//Styles
import Styles from "./marketing-location.module.scss";

//Assets
import Dot from "@assets/svgs/dot-grey.svg";
import PinRed from "@assets/svgs/pin-red.svg";
import C21Background from "@assets/svgs/C21Background.svg";

//Component
import GoogleMaps from "@components/reuseables/GoogleMaps";

//Helpers
import parsePhoneNumber from "@helpers/parsePhoneNumber";

const MarketingLocation = ({ data, refKontak }) => {
  return (
    <div className={Styles["wrapper"]} ref={refKontak}>
      <div className={Styles["content"]}>
        <div className={Styles["left"]}>
          <GoogleMaps
            isOpenGoogleMapOnClick={true}
            position={data.position}
            street={data.street}
          />
        </div>

        <div className={Styles["right"]}>
          <p className={Styles["title"]}>Tetap Terhubung dengan</p>
          <p className={Styles["name"]}>{data.name}</p>
          <p className={Styles["company"]}>
            Anda bisa juga bisa mengunjungi atau menghubungi kantor{" "}
            <span>Century 21 {data?.office}</span>
          </p>
          <p className={Styles["lokasi-kantor"]}>Lokasi Kantor:</p>
          <p className={Styles["location"]}>
            {data.officeLocation}
          </p>
          <p className={Styles["phone-label"]}>Telepon Kantor:</p>
          <p className={Styles["phone"]}>
            {parsePhoneNumber(data.officePhone || "")}
          </p>
        </div>
      </div>

      <img src={"https://s3.ap-southeast-1.amazonaws.com/production.storages/storages/store/online-profile/C21Background.svg"} className={Styles["bg-c21"]} />
    </div>
  );
};

export default MarketingLocation;
