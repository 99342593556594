import { useState, useEffect } from 'react';

const ORIENTATION = {
  landscape: "landscape",
  portrait: "portrait",
  square: "square"
}

const useImageOrientation = (src) => {
  const [imgSize, setImgSize] = useState({
    width: null,
    height: null
  });
  const [imageOrientation, setImageOrientation] = useState(null);

  useEffect(() => {
    const classifyImage = (url) => {
      const img = new Image();
      img.src = url;

      img.onload = () => {
        const width = img.width;
        const height = img.height;

        setImgSize({
          width,
          height
        })

        if (width > height) {
          setImageOrientation('landscape');
        } else if (height > width) {
          setImageOrientation('portrait');
        } else {
          setImageOrientation('square');
        }
      };

      img.onerror = () => {
        console.error('Error loading image.');
        setImageOrientation('error');
      };
    };

    if (src) {
      classifyImage(src);
    }
  }, [src]);

  return { imageOrientation, ORIENTATION, imgSize };
};

export default useImageOrientation;
