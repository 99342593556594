import React, { useEffect } from "react";

export default function useEnterKey(callback) {
  useEffect(() => {
    function handleKeyPress(event) {
      if (event.key === "Enter") {
        callback();
      }
    }
    document.addEventListener("keydown", handleKeyPress);
    return () => {
      document.removeEventListener("keydown", handleKeyPress);
    };
  }, [callback]);
}