import React, { useMemo, useState } from 'react'
import _ from "lodash";
import dayjs from 'dayjs';

//Components
import Header from '../header'
import Judul from '../judul'
import ListingImages from '../listing-images'
import PriceAndDate from '../price-and-date'
import SpesifikasiRumah from '../spesifikasi-rumah'
import Deskripsi from '../deskripsi'
import KondisiRumah from '../kondisi-rumah'
import FasilitasRumah from '../fasilitas-rumah'
import PerabotanRumah from '../perabotan-rumah'
import FasilitasPerumahan from '../fasilitas-perumahan'
import MarketingAgent from '../marketing-agent'
import HubungiMA from '../hubungi-ma'
import ModalLeads from "../modal-leads";

function Main({ data, images, location }) {
  const [isOpenModalFormLead, setIsOpenModalFormLead] = useState(false)

  const getValueFromData = (str = "") => _.get(data, str)
  const getValueFromlocation = (str = "") => _.get(location, str)

  //Helpers
  const handleTanggal = (date = "", format) => {
    if (!date) return "";
    let result = dayjs(date).format(format)

    if (result == "Invalid Date") {
      const newDate = date.replace(/-/g, '/')
      result = dayjs(newDate).format(format)
    }

    return result
  }

  const messageAgent = () => setIsOpenModalFormLead(true)

  //Mapping payloads
  const payloadJudul = useMemo(() => {
    return {
      id: getValueFromData("property.id"),
      category: getValueFromData("property.property_category.name"),
      type: getValueFromData("property.property_type.name"),
      furnitureCondition: getValueFromData("property.furniture_condition"),
      title: getValueFromData("property.title"),
      location: `${getValueFromlocation("area.name")}, ${getValueFromlocation("city.name")}, ${getValueFromlocation("province.name")}`,
      position: {
        lat: +getValueFromlocation("latitude"),
        lng: +getValueFromlocation("longitude")
      },
      street: getValueFromlocation("street"),
      slug: getValueFromData("property.slug"),
    }
  }, [data])

  const payloadPriceAndDate = useMemo(() => {
    return {
      createdAt: handleTanggal(getValueFromData("property.created_at"), "DD/MM/YYYY"),
      updatedAt: handleTanggal(getValueFromData("property.updated_at"), "DD/MM/YYYY, HH:MM A"),
      price: getValueFromData("property.price"),
      unitPrice: getValueFromData("property.unit_price.name"),
      minRent: getValueFromData("property.min_rent"),
      type: getValueFromData("property.property_type.name"),
      isArchived: getValueFromData("property.is_archived"),
      hargaTurun: getValueFromData("property.summary.historical_price")
    }
  }, [data])

  const payloadSpesifikasiRumah = useMemo(() => {
    return {
      floors: getValueFromData("property.number_of_floors"),
      bedroom: getValueFromData("property.bedroom"),
      additionalBedroom: getValueFromData("property.additional_bedroom"),
      bathroom: getValueFromData("property.bathroom"),
      additionalBathroom: getValueFromData("property.additional_bathroom"),

      luasBangunan: getValueFromData("property.building_area"),
      lebarBangunan: getValueFromData("property.building_width"),
      panjangBangunan: getValueFromData("property.building_length"),

      luasTanah: getValueFromData("property.surface_area"),
      lebarTanah: getValueFromData("property.land_width"),
      panjangTanah: getValueFromData("property.land_length"),

      garageCap: getValueFromData("property.garage_capacity"),
      parkCap: getValueFromData("property.parking_capacity"),

      electricity: getValueFromData("property.electrical_power")
    }
  }, [data])

  const payloadKondisiRumah = useMemo(() => {
    return {
      tahunDibangun: getValueFromData("property.year"),
      sertifikat: getValueFromData("property.ownership_status.name"),
      menghadap: getValueFromData("property.face"),
      kondisi: getValueFromData("property.property_condition"),
    }
  }, [data])

  const payloadFasilitasRumah = useMemo(() => {
    return {
      telepon: getValueFromData("property.number_of_phone_lines"),
      tamanPribadi: _.find(getValueFromData("property.facilities"), { name: "Taman" }),
      kolam: _.find(getValueFromData("property.facilities"), { name: "Kolam Renang" }),
      AC: _.find(getValueFromData("property.facilities"), { name: "AC" }),
      CCTV: _.find(getValueFromData("property.facilities"), { name: "CCTV" })
    }
  }, [data])

  const payloadPerabotanRumah = useMemo(() => {
    return {
      AC: _.find(getValueFromData("property.facilities"), { name: "AC" }),
      CCTV: _.find(getValueFromData("property.facilities"), { name: "CCTV" }),
    }
  }, [data])

  const payloadFasilitasPerumahan = useMemo(() => {
    return {
      keamanan: _.find(getValueFromData("property.environment_facilities"), { name: "Keamanan 24 Jam" }),
      taman: _.find(getValueFromData("property.environment_facilities"), { name: "Taman" }),
      track: _.find(getValueFromData("property.environment_facilities"), { name: "Jogging Track" }),
      masjid: _.find(getValueFromData("property.environment_facilities"), { name: "Masjid" }),
      CCTV: _.find(getValueFromData("property.environment_facilities"), { name: "CCTV" }),
      kolam: _.find(getValueFromData("property.environment_facilities"), { name: "Kolam Renang" }),
    }
  }, [data])

  const payloadMarketingAgent = useMemo(() => {
    return {
      name: getValueFromData("user.full_name"),
      company: getValueFromData("user.company.name"),
      userImage: getValueFromData("user.user_image.online_profile.url"),
      id: getValueFromData("user.id"),
    }
  }, [data])

  return (
    <div id='detail-listing-2-0-mobile-wrapper'>
      <div className='detail-listing-2-0-content-wrapper'>
        <Header />
        <Judul data={payloadJudul} />
        <ListingImages videoUrl={data?.video?.url || data?.property?.video?.url || ""} images={images} />
        <PriceAndDate data={payloadPriceAndDate} />
        <SpesifikasiRumah category={payloadJudul.category} data={payloadSpesifikasiRumah} />
        <Deskripsi description={getValueFromData("property.description")} />
        <KondisiRumah data={payloadKondisiRumah} />
        <FasilitasRumah data={payloadFasilitasRumah} />
        <FasilitasPerumahan data={payloadFasilitasPerumahan} />
        <MarketingAgent data={payloadMarketingAgent} />
        <HubungiMA messageAgent={messageAgent} phone={getValueFromData("user.phone")} name={getValueFromData("user.company.name")} />
      </div>

      <ModalLeads 
        full_name={data?.user?.full_name} 
        phone_number={data?.user?.phone} 
        onClose={() => setIsOpenModalFormLead(false)} 
        isOpen={isOpenModalFormLead} 
        property_id={payloadJudul.id}
        user_id={data?.user?.id}
        slug={data?.slug ?? data?.property?.slug}
      />
    </div>
  )
}

export default Main