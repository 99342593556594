import { useEffect } from 'react'

import Style from "./landscape.module.scss"

// Hooks
import useHtmlToImage from 'hooks/useHmlToImage';

// Component
import ContentWrapper from "components/reuseables/download-feed-story/content-wrapper"

// Utils
import useRasio from '@components/download-instagram-story/useRasio';

const S3_CLOUDFRONT = process.env.NEXT_PUBLIC_S3_CLOUDFRONT_URL
const LOGO_CENTURY = `${S3_CLOUDFRONT}/storages/store/instagram-story/century-21-white.png`
const LOGO_ONE21_BLACK_GOLD = `${S3_CLOUDFRONT}/storages/store/instagram-story/one21-white-gold.png`
const DotIcon = `${S3_CLOUDFRONT}/storages/store/instagram-story/dot.png`

const Feature = ({
  surfaceArea,
  buildingArea,
  bedroom,
  additionalBedroom,
  bathroom,
  additionalBathroom,
  propertyCategory
}) => {
  const isApartment = propertyCategory.toLowerCase() === "apartment"

  const completeTop = !!surfaceArea && !!buildingArea;
  const halfTop = !!surfaceArea || !!buildingArea;

  const completeBottom = !!bedroom && !!bathroom
  const halfBottom = !!bedroom || !!bathroom
  return (
    <div className={Style['wrapper-feature']}>
      {halfTop && <div className={Style['feature']}>
        {!!surfaceArea && <p>LT.{surfaceArea} m<sup>2</sup></p>}
        {completeTop && <img src={DotIcon} alt='Dot' className={Style['dot']} />}
        {!!buildingArea && <p>LB.{buildingArea} m<sup>2</sup></p>}
      </div>}

      {halfTop && halfBottom && <div className={Style['divider']} />}

      {halfBottom && <div className={Style['feature']}>
        {!!(isApartment && +bedroom === 0) ? (
          <p>Tipe: Studio</p>
        ) : (
          <>
            {!!bedroom && <p>KT.{bedroom} {!!additionalBedroom && `+ ${additionalBedroom}`}</p>}
            {completeBottom && <img src={DotIcon} alt='Dot' className={Style['dot']} />}
            {!!bathroom && <p>KM.{bathroom} {!!additionalBathroom && `+ ${additionalBathroom}`}</p>}
          </>)}
      </div>}
    </div>
  )
}

const Landscape = ({
  imageUrl,
  propertyCategory,
  propertyType,
  area,
  price,
  rentType,
  id,
  imgSize,
  ...rest
}) => {
  const getRatio = useRasio()
  const ratio = getRatio("landscape", imgSize.width / imgSize.height)

  const { cardRef, convertToPdf, isLoading, isSuccess } = useHtmlToImage("storages/store/instagram-story/");

  useEffect(() => {
    convertToPdf(id)
  }, [])

  return (
    <ContentWrapper igType="story" id={id} isSuccess={isSuccess} isLoading={isLoading}>
      <div ref={cardRef} className={Style["content"]}>
        <div className={`${Style[ratio === "4:3" ? "background-image-4-3" : ratio === "5:4" ? "background-image-5-4" : "background-image"]}`}>
          <img src={imageUrl} alt={`${propertyCategory}-${propertyType}-${area}`} />
        </div>

        <div className={Style["informations"]}>
          <div className={`${Style[ratio === "4:3" ? "info-top-4-3" : ratio === "5:4" ? "info-top-5-4" : "info-top"]}`}>
            <img className={Style["century-21"]} src={LOGO_CENTURY} alt="CENTURY 21" />
            <div className={Style["wrapper-powered-by"]}>
              <p className={Style["powered-by"]}>Powered by</p>
              <img className={Style["one-21"]} src={LOGO_ONE21_BLACK_GOLD} alt="ONE 21" />
            </div>
          </div>

          <div className={Style["text-bg-color"]}>
            <p className={Style["property-type"]}>{propertyCategory} {propertyType}</p>
            <p className={Style["property-location"]}>{area}</p>

            <div className={Style["wrapper-price-feature"]}>
              <div className={Style["wrapper-price"]}>
                <p className={Style["price"]}>Rp<span className={Style["price-bold"]}>{price}</span>{!!rentType && <span>/{rentType}</span>}</p>
              </div>
              <Feature propertyCategory={propertyCategory} {...rest} />
            </div>
          </div>
        </div>
      </div>
    </ContentWrapper>
  )
}

export default Landscape