import React, { useMemo } from "react";
import _ from "lodash"

//Sttyles
import Styles from "./achievement.module.scss";
import { useGetViewType } from "hooks/useGetViewType";

const T5Achievement = ({ title, year, info, years }) => (
  <div className={Styles["t5-achievement"]}>
    <div className={Styles["wrapper-title-info"]}>
      <p className={Styles["title"]}>{title}</p>
      {/* <p className={Styles["info"]}>{info}</p> */}
    </div>
    <div className={Styles["wrapper-year-years"]}>
      <p className={Styles["year"]}>{year}</p>
      <p className={Styles["years"]}>{years}</p>
    </div>
  </div>
);

const Achievement = ({ data, bannerAwards }) => {
  const { windowSize } = useGetViewType()

  const Top5 = useMemo(() => {
    if (!data?.length) return [];

    const grouped = data.reduce((accumulator, value) => {
      accumulator[value.title] = accumulator[value.title] || []
      accumulator[value.title].push(value)

      return accumulator
    }, Object.create(null))

    let unique = _.chain(data).map('title').uniq().value().sort((a, b) => b - a).filter((a, i) => i < 5)

    let result = unique.map(u => {
      let currentValue = grouped[u];
      let currentYears = _.chain(currentValue).map("year").uniq().value().filter((a, i) => i < 5)

      return {
        year: currentYears[0],
        years: currentYears.filter((c, i) => i > 0),
        title: u
      }
    })

    return result
  }, [data])

  const OtherAchivement = useMemo(() => {
    if (!data?.length && data?.length < 5) return ""

    return _.chain(data).map('title').uniq().value().sort((a, b) => b - a).filter((a, i) => i >= 5).join(", ")
  }, [data])

  const BannerImage = useMemo(() => {
    const isMobileView = windowSize[0] <= 1068;

    if (!bannerAwards?.length) return "";

    const centurion = bannerAwards.find((i) => i.name.toLowerCase() === "centurion")

    const assets = !centurion ? bannerAwards?.[0]?.image : centurion?.image

    if(isMobileView) {
      return assets?.mobile_version_url ?? ""
    } 
    
    return assets?.url ?? ""
  }, [bannerAwards, windowSize])

  return (
    <div className={Styles["wrapper"]}>
      {!!BannerImage && <div className={Styles["wrapper-banner"]}>
        <img
          className={Styles["banner"]}
          src={BannerImage}
          alt="banner"
        />
      </div>}
      <div className={Styles["content"]}>
        <div className={Styles["wrapper-achievement"]}>
          <div className={Styles["wrapper-top-five"]}>
            {!!Top5?.length && Top5.map((item, key) => <T5Achievement
              key={"top5-achievement-" + key}
              title={"TOP Marketing Associate by Personal Commission Region 2023 A"}
              // title={item.title}
              year={item.year}
              years={item?.years?.length ? item?.years.join(", ") : ""}
            />)}
          </div>
          {/* {!!OtherAchivement && <>
            <div className={Styles["penghargaan-lainnya"]}>
              <span>Penghargaan Lainnya yang Pernah Dicapai</span>
              <div className={Styles["line"]}></div>
            </div>
            <p className={Styles["other-achievement"]}>{OtherAchivement}</p>
          </>} */}
        </div>
      </div>
    </div>
  );
};

export default Achievement;
