import React from 'react'

//Assets
import DotGrey from "../../../../../assets/svgs/dot-grey.svg";
import ArrowDown from "assets/svgs/arrow-down-black-24px.svg";
import Question from "assets/svgs/question.svg";

const isBrowser = () => typeof window !== 'undefined';

function MainInfo({ data, messageAgent }) {
  const {
    category,
    furnitureCondition,
    id,
    location,
    position,
    street,
    title,
    type,
    createdAt,
    updatedAt
  } = data || {};

  return (
    <div className='main-info-wrapper'>
      <div className='category-wrapper'>
        <span>{type}</span>
        <span>{category}</span>
      </div>

      <div className='id-listing-wrapper'>
        <p className='id-listing'>ID Listing: <span className='id-hash'>#{id}</span></p>
        <DotGrey className="dot-grey" />
        <span className='furniture-condition'>{furnitureCondition}</span>
      </div>

      <h1 className='detail-listing-title'>{title}</h1>
      <p className='detail-listing-street'>{street}</p>
      
      <div className='detail-listing-tanggal'>
        <span className='dipost'>Dipost {createdAt}</span>
        <DotGrey className="dot-grey" />
        <span className='diperbaharui'>Diperbaharui <span className='updated-at'>{updatedAt}</span></span>
      </div>

      <div className='propertyinfo-wrapper-button' >
        <button className='detail-listing'><ArrowDown /> <span>Lihat detail listing</span></button>
        <button className='tanya-listing' onClick={isBrowser && messageAgent}><Question /> <span>Info lebih lanjut</span></button>
      </div>
      <div className='propertyinfo-info'>
        <p>Dengan memilih tanya listing, Anda akan kami arahkan ke aplikasi WhatsApp untuk terhubung dengan marketing dari properti ini.</p>
      </div>
    </div>
  )
}

export default MainInfo