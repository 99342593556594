import React, { useMemo, useState } from 'react'

//Components
import Modal from "../modal"

//Assets
import CloseIcon from "../../../../assets/svgs/X.svg";

function Deskripsi({ description }) {
  const [openModal, setOpenModal] = useState(false)

  const showBtnBacaLebihLengkap = useMemo(() => {
    if (!description) return false;

    return (description?.length + description?.split(" ")?.length - 1) >= 200
  }, [description])

  if(!description) return null;
  return (
    <div className='detail-listing-deskripsi'>
      <p className='header-deskripsi'>Deskripsi</p>
      <p className={`body-deskripsi ${showBtnBacaLebihLengkap ? "show-read-more" : ""}`}>{description}</p>
      {showBtnBacaLebihLengkap ? <p onClick={() => setOpenModal(true)} className='footer-deskripsi'>Baca lebih lengkap</p> : null}
      
      <Modal isOpen={openModal}>
        <div className='deskripsi-modal-content'>
          <div className='modal-deskripsi-header'>
            <p>Deskripsi</p>
          </div>
          <div className='modal-deskripsi-body'>
            <p className='content-deskripsi-modal'>{description}</p>
          </div>
          <div className='modal-deskripsi-footer'>
            <div className='modal-deskripsi-exit-btn' onClick={() => setOpenModal(false)}>
              <CloseIcon className="modal-deskripsi-exit-icon" />
              <span>Tutup</span>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  )
}

export default Deskripsi