import React from "react";

// Style
import Style from "./self-description.module.scss";

// Asset
import KutipSatu from "assets/svgs/petik-satu.svg";

const SelfDescription = ({ windowWidth, description, agentName, companyName }) => {

  const defaultDescription = `Selamat datang! Perkenalkan Saya ${agentName} dari CENTURY 21 ${companyName}. Untuk apapun kebutuhan properti Anda,Saya siap membantu dengan sepenuh hati dan memberikan layanan kualitas terbaik. Temukan properti idaman Anda bersama CENTURY 21!`

  if (windowWidth <= 1023) {
    return (
      <div className={Style["wrapper"]}>
        <div className={Style["content"]}>
          <div className={Style["wrapper-kutip"]}>
            <KutipSatu />
            <KutipSatu />
          </div>
          <p className={Style["description"]}>
            {!!description ? description : defaultDescription}
          </p>
        </div>
      </div>
    );
  }

  return (
    <div className={Style["wrapper-dekstop"]}>
      <div className={Style["content"]}>
        <div className={Style["description"]}>
          <div className={Style["wrapper-grey-union"]}>
            <img
              src={"https://s3.ap-southeast-1.amazonaws.com/production.storages/storages/store/online-profile/Union.svg"}
              alt="union"
              className={Style["grey-union"]}
            />
            <img
              src={"https://s3.ap-southeast-1.amazonaws.com/production.storages/storages/store/online-profile/Union.svg"}
              alt="union"
              className={Style["grey-union"]}
            />
          </div>
          <p>{!!description ? description : defaultDescription}</p>
        </div>
      </div>

      <div className={Style["divider"]}></div>
    </div>
  )

};

export default SelfDescription;
