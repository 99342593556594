import React, { useEffect, useState } from "react";

//Styles
import Styles from "./header.module.scss";

const isBrowser = () => typeof window !== "undefined";

const Header = ({
  scrollToRef,
  scrollToListingKantorRef,
  scrollToKontakRef,
  forProfile,
  windowWidth,
}) => {
  const RenderEmblem = React.useMemo(() => {
    if (windowWidth <= 1023) {
      switch (forProfile.careerLevel) {
        case "Master Bronze":
          return (
            <img
              src={
                "https://s3.ap-southeast-1.amazonaws.com/production.storages/storages/store/online-profile/MasterBronze-1440.svg"
              }
              className={Styles["emblem"]}
            />
          );
        case "Master Silver":
          return (
            <img
              src={
                "https://s3.ap-southeast-1.amazonaws.com/production.storages/storages/store/online-profile/MasterSilver-375.svg"
              }
              className={Styles["emblem"]}
            />
          );
        case "Master Gold":
          return (
            <img
              src={
                "https://s3.ap-southeast-1.amazonaws.com/production.storages/storages/store/online-profile/MasterGold-375.svg"
              }
              className={Styles["emblem"]}
            />
          );
        default:
          return null;
      }
    }
    switch (forProfile.careerLevel) {
      case "Master Bronze":
        return (
          <img
            src={
              "https://s3.ap-southeast-1.amazonaws.com/production.storages/storages/store/online-profile/MasterBronze-1440.svg"
            }
            className={Styles["emblem"]}
          />
        );
      case "Master Silver":
        return (
          <img
            src={
              "https://s3.ap-southeast-1.amazonaws.com/production.storages/storages/store/online-profile/MasterSilver-1440.svg"
            }
            className={Styles["emblem"]}
          />
        );
      case "Master Gold":
        return (
          <img
            src={
              "https://s3.ap-southeast-1.amazonaws.com/production.storages/storages/store/online-profile/MasterGold-1440.svg"
            }
            className={Styles["emblem"]}
          />
        );
      default:
        return null;
    }
  }, [windowWidth]);

  const RenderButton = React.useMemo(() => {
    if (windowWidth <= 1023) {
      return (
        <button onClick={scrollToRef}>
          {" "}
          <img
            src={
              "https://s3.ap-southeast-1.amazonaws.com/production.storages/storages/store/online-profile/ArrowDown.svg"
            }
            alt="."
            className={Styles["arrow-down"]}
          />{" "}
          Scroll untuk melihat lebih banyak
        </button>
      );
    }
    return null;
  }, [windowWidth]);

  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (isBrowser()) {
        const isScrolled = windowWidth <= 1023 ? false : window.scrollY > 0; // Change 100 to the desired threshold
        setScrolled(isScrolled);
      }
    };

    isBrowser() && window.addEventListener("scroll", handleScroll);
    return () => {
      isBrowser() && window.removeEventListener("scroll", handleScroll);
    };
  }, [isBrowser, windowWidth]);

  return (
    <div className={Styles["wrapper-outer"]}>
      <div className={`${Styles["wrapper"]} ${scrolled && Styles["scrolled"]}`}>
        <div className={Styles["content"]}>
          <img
            src={
              "https://s3.ap-southeast-1.amazonaws.com/production.storages/storages/store/online-profile/C21_Seal_ObsessedGray_4C.png"
            }
            alt="logo"
            className={Styles["logo-c21"]}
          />
          <div className={Styles["menus"]}>
            <a onClick={scrollToRef} className={Styles["menu"]}>
              LISTING SAYA
            </a>
            <a onClick={scrollToListingKantorRef} className={Styles["menu"]}>
              LISTING KANTOR
            </a>
            <a onClick={scrollToKontakRef} className={Styles["menu"]}>
              KONTAK
            </a>
          </div>
          {/* {RenderButton} */}
        </div>
      </div>
      {RenderEmblem}
    </div>
  );
};

export default Header;
