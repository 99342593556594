import React from 'react'

function Modal({ isOpen, children }) {
  if (!isOpen) return null;

  return (
    <div id='custom-modal-wrapper'>
      {children}
    </div>
  )
}

export default Modal