import { useState } from "react";

//Styles
import Styles from "./listing-marketer.module.scss";

//Components
import ListingCard from "@components/reuseables/ListingCard";
import ModalListing from "@components/reuseables/ModalListing";

const ListingMarketer = ({ data, marketingId, name, refListing, windowWidth }) => {
  const [isOpenModal, setIsOpenModal] = useState(false);
  const totalItem = windowWidth > 1500 ? 8 : 6;

  return (
    <div className={Styles["wrapper"]} ref={refListing}>
      <div className={Styles["content"]}>
        <div className={Styles["header"]}>
          <div className={Styles["title-subtitle"]}>
            <p className={Styles["title"]}>Listing Saya</p>
            <p className={Styles["sub-title"]}>
              Daftar properti yang saat ini sedang dipasarkan oleh <span>{name}</span>
            </p>
          </div>
          <button
            onClick={() => setIsOpenModal(true)}
            className={Styles["btn"]}
          >
            <span>Lihat listing lainnya</span>
          </button>
        </div>
        <div className={Styles["wrapper-images"]}>
          {!!data.length &&
            data
              .filter((i, idx) => idx < totalItem)
              .map((item, index) => {
                return (
                  <ListingCard
                    marketingId={marketingId}
                    key={index}
                    item={item}
                  />
                );
              })}
        </div>
      </div>
      {isOpenModal && (
        <ModalListing
          name={name}
          marketingId={marketingId}
          data={data}
          onClose={() => setIsOpenModal(false)}
        />
      )}
    </div>
  );
};

export default ListingMarketer;
