import React from "react";

// Styles
import Styles from "./listing-card.module.scss";

//Assets
import Dot from "@assets/svgs/dot-grey.svg";
import PinRed from "@assets/svgs/pin-red.svg";

const EmptyImage =
  "https://s3.ap-southeast-1.amazonaws.com/production.storages/storages/store/property-attachments/original/Empty.png";
const ListingCard = ({ item = {}, marketingId }) => {
  const isApartment = item?.category?.toLowerCase() === "apartment";

  const onClick = () => {
    window.location.href = `/ma-properti?marketingId=${marketingId}&propertySlug=${item.slug}`;
  };

  function detectString(input) {
    const regexHari = /\bhari\b/gi;
    const regexMinggu = /\bminggu\b/gi;
    const regexBulan = /\bbulan\b/gi;
    const regexTahun = /\btahun\b/gi;
    const regexM2 = /\bm2\b/gi;

    if (regexHari.test(input)) {
      return '/Hari';
    } else if (regexMinggu.test(input)) {
      return '/Minggu';
    } else if (regexBulan.test(input)) {
      return '/Bulan';
    } else if (regexTahun.test(input)) {
      return '/Tahun';
    } else if (regexM2.test(input)) {
      return '/M2';
    } else {
      return null; // or any other value or message if none of the patterns match
    }
  }

  const RenderBedroom = () => {
    if (isApartment && +item.kamarTidur === 0) {
      return (
        <span>Tipe <span className={Styles["black"]}>Studio</span></span>
      )
    }

    return (
      <span>
        KT: <span className={Styles["black"]}>{item.kamarTidur}</span>
        {!!item.kamarTidurTambahan &&
          <>
            <span className={Styles["black"]}>
              {item.kamarTidurTambahan}
            </span>
          </>
        }
      </span>
    )
  }

  return (
    <div className={Styles["wrapper"]} onClick={onClick}>
      <div className={Styles["image-wrapper"]}>
        <img src={item.image || EmptyImage} alt={"..."} />
      </div>

      <div className={Styles["listing-info"]}>
        <p className={Styles["location"]}>
          <PinRed /> {item.area}, {item.city}
        </p>
        <p className={Styles["title"]}>{item.title}</p>

        <div className={Styles["details"]}>
          {RenderBedroom()}
          <Dot />
          <span>
            KM: <span className={Styles["black"]}>{item.kamarMandi}</span>
            {!!item.kamarMandiTambahan &&
              <>
                <span>+</span>
                <span className={Styles["black"]}>
                  {item.kamarMandiTambahan}
                </span>
              </>}
          </span>
          <Dot />
          <span>
            LT: <span className={Styles["black"]}>{item.luasTanah}</span>m
            <sup>2</sup>
          </span>
        </div>

        <p className={Styles["price-label"]}>Harga:</p>
        <p className={Styles["price"]}>
          <span>Rp</span>
          {item.price} {!!item?.isRent && <span>{detectString(item?.unitPriceName)}</span>}
        </p>
      </div>
    </div>
  );
};

export default ListingCard;
