import React from 'react'

import GreenCheck from "../../../../assets/svgs/green-check.svg";

function PerabotanRumah({ data }) {
  const { AC, CCTV } = data
  
  if(!AC && !CCTV) return null;
  return (
    <div className='perabotan-rumah'>
      <p className='header-perabotan-rumah'>Perabotan rumah</p>
      {AC ? <div className='section-perabotan-rumah'>
        <span>AC</span>
        <GreenCheck className="green-check" />
      </div> : null}
      {CCTV ? <div className='section-perabotan-rumah'>
        <span>CCTV</span>
        <GreenCheck className="green-check" />
      </div> : null}
    </div>
  )
}

export default PerabotanRumah