import React from 'react'

import GreenCheck from "../../../../assets/svgs/green-check.svg";

function FasilitasPerumahan({ data }) {
  const { keamanan, taman, track, masjid, CCTV, kolam } = data

  const fasilitas = React.useMemo(() => {
    let result = []

    if (keamanan) result.push({ name: "Keamanan 24 jam" })
    if (taman) result.push({ name: "Taman" })
    if (CCTV) result.push({ name: "CCTV" })
    if (kolam) result.push({ name: "Kolam renang umum" })
    if (track) result.push({ name: "Jogging track" })
    if (masjid) result.push({ name: "Tempat ibadah" })

    return result
  }, [data])

  if (!fasilitas?.length) return null;
  return (
    <div className='fasilitas-perumahan'>
      <p className='fasilitas-lingkungan-title'>Fasilitas rumah</p>
      <div className='wrapper-section-lingkungan'>
        {fasilitas.map((item, index) => {
          return (
            <div key={index} className='section-fasilitas-lingkungan'>
              <span>{item.name}</span>
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default FasilitasPerumahan