import React, { useEffect, useState } from 'react';
import { Card, CardBody, CardTitle, Button } from 'reactstrap';

export default function ListingInfo(props) {
  const { data } = props;
  const [text, setText] = useState('');
  useEffect(() => {
    const textWa = `Halo ${data.user.full_name},\n\nSaya tertarik dengan listing anda di One21\n${window.location.href}\n\nMohon hubungi saya di nomor ini, terima kasih.\n\n\nPesan ini dikirim via Mobile Website One21`;
    setText(textWa);
  }, []);

  function callAgent(phone) {
    window.location.href = `tel:${phone}`;
  }

  function messageAgent(text, phone) {
    return `https://api.whatsapp.com/send?text=${encodeURI(
      text,
    )}&phone=${phone}`;
  }
  return (
    <div>
      {data && (
        <Card style={{ border: 'none', borderRadius: 0, marginBottom: '1rem' }}>
          <CardBody>
            <CardTitle
              style={{ fontSize: '1.1rem', fontFamily: 'AvenirNext-Bold' }}
            >
              Agent
            </CardTitle>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <div style={{ marginRight: '1.5rem' }}>
                {data?.user?.full_name && (
                  <span style={{ display: 'block' }}>
                    {data?.user?.full_name}
                  </span>
                )}
                {data?.user?.phone && (
                  <span style={{ display: 'block' }}>{data?.user?.phone}</span>
                )}
                {data?.user?.email && (
                  <span style={{ display: 'block' }}>{data?.user?.email}</span>
                )}
                {data?.user?.company?.name && (
                  <small className="text-muted">
                    {data?.user?.company?.name}
                  </small>
                )}
              </div>
            </div>
            <div
              style={{ position: 'absolute', top: '1.2rem', right: '1.2rem' }}
            >
              {
                <img
                  src={
                    data?.user?.user_image?.public?.thumbnail?.url ||
                    `https://s3-ap-southeast-1.amazonaws.com/staging-cdn.homebase.id/img/logo-new-circle.jpg`
                  }
                  style={{ height: 86, width: 86 }}
                  className="img-fluid rounded-circle"
                />
              }
            </div>
            <hr className="my-2" />
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <Button
                onClick={() => callAgent(data?.user?.phone)}
                className="w-50 btn-outline-secondary"
                style={{
                  border: '1px solid #E0E0E0',
                  color: '#000',
                  borderRadius: 7,
                  marginTop: 10,
                  marginBottom: 10,
                  marginLeft: 15,
                  fontSize: 12,
                  width: 147,
                  height: 38,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <img
                  style={{ height: 20, marginRight: 10 }}
                  src={`../../static/images/icons/one21/phone-black.png`}
                />
                Telp
              </Button>
              <a
                target="_blank"
                href={messageAgent(text, data?.user?.phone)}
                className="w-50 btn"
                style={{
                  border: '1px solid #E0E0E0',
                  color: '#000',
                  borderRadius: 7,
                  marginTop: 10,
                  marginBottom: 10,
                  marginLeft: 15,
                  fontSize: 12,
                  width: 147,
                  height: 38,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <img
                  style={{ height: 20, marginRight: 10 }}
                  src={`../../static/images/icons/one21/comment.png`}
                />
                WhatsApp
              </a>
            </div>
          </CardBody>
        </Card>
      )}
    </div>
  );
}
