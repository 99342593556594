import React from 'react'

//Components
import GoogleMap from "./GoogleMap";

function LokasiProperti({ data }) {
  const { location, position, street } = data
  return (
    <div className='dekstop-lokasi-properi'>
      <div className='label-dan-jalan'>
        <p>Lokasi Properti</p>
        <p>{street}</p>
      </div>
      <div className='lokasi-properti-gmap'>
        <GoogleMap position={position} street={street} />
      </div>
    </div>
  )
}

export default LokasiProperti