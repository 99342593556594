import React, { useState } from 'react'

import GoogleMap from "./GoogleMap";
import Modal from "../modal"

//Assets
import C21Logo from "../../../../assets/svgs/map-pin.svg";
import CloseIcon from "../../../../assets/svgs/X.svg";
import Dot from "@assets/svgs/dot-grey.svg";

function Judul({ data = {} }) {
  const { id, category, type, furnitureCondition, title, location, position, street } = data;
  const [openModal, setOpenModal] = useState(false)

  const getMobileOperatingSystem = React.useCallback(() => {
    var userAgent = navigator.userAgent || navigator.vendor || window.opera;
    console.log(userAgent)
    // Windows Phone must come first because its UA also contains "Android"
    if (/windows phone/i.test(userAgent)) {
      return true
      // return "Windows Phone";
    }

    if (/android/i.test(userAgent)) {
      return true

      // return "Android";
    }

    // iOS detection from: http://stackoverflow.com/a/9039885/177710
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      return true

      // return "iOS";
    }

    return false;
  }, [])

  const handleOpenId = () => {
    if (!getMobileOperatingSystem()) return;

    const slug = data.slug;
    const url = `https://one21.page.link/?link=https://www.century21.com/detaillisting/?slug=${slug}&apn=com.one21&isi=1540220733&ibi=com.one21`;

    window.open(url, "_blank")
  }

  return (
    <div className='detail-listing-judul'>
      <div className='id-listing-wrapper'>
        <p className='id-listing' onClick={handleOpenId}>ID Listing <span>#{id}</span></p>
        <div className='property-label-wrapper'>
          <div className='property-label'><span>{type}</span></div>
          <div className='property-label'><span>{category}</span></div>
        </div>
      </div>

      <div className="type-and-furnished">
        <p className='category'>{category}</p>
        {furnitureCondition &&
          <>
            <Dot className='dot' />
            <p className='kondisi-perabotan'>{furnitureCondition}</p>
          </>
        }
      </div>
      <p className='judul-listing'>{title}</p>
      <div onClick={() => setOpenModal(true)} className='listing-location'>
        {/* <C21Logo className="map-pin-logo" /> */} <span className='location'>{location}</span>
      </div>

      <Modal isOpen={openModal}>
        <div className='judul-modal-content'>
          <div className='modal-judul-body'>
            <GoogleMap position={position} street={street} />
          </div>
          <div className='modal-title-street'>
            <p className='judul-modal-location'>Alamat lengkap properti</p>
            <p className='street-modal-location'>{street}</p>
          </div>
          <div className='modal-judul-footer'>
            <div className='modal-judul-exit-btn' onClick={() => setOpenModal(false)}>
              <CloseIcon className="modal-judul-exit-icon" />
              <span>Tutup</span>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  )
}

export default Judul