import React from 'react'

//Assets
import ArrowDown from "../../../../assets/svgs/arrow-down.svg";

function LihatDetailProperty() {
  return (
    <div className='lihat-detail-property'>
      <span>Lihat detail properti</span>
      <ArrowDown className="arrow-down" />
    </div>
  )
}

export default LihatDetailProperty