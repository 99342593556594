import React from "react";

//Styles
import Styles from "./c21-info.module.scss";

const C21Info = () => {
  return (
    <div className={Styles["wrapper"]}>
      <div className={Styles["content"]}>
        <div className={Styles["header"]}>
          <p className={Styles["title"]}>Century 21 Indonesia</p>
          <p className={Styles["sub-title"]}>Sekilas sejarah tentang <span>Century 21 Indonesia</span></p>
        </div>
        <p className={Styles["deskripsi"]}>
          CENTURY 21 Indonesia telah resmi beroperasi sejak tahun 1997 di bawah
          otoritas PT. SAGOTRA USAHA, salah satu anak perusahaan dari Ciputra
          Group, perusahaan properti terkemuka yang didirikan oleh profesional
          properti Indonesia, Dr. (HC) Ir. Ciputra. Grup Ciputra memiliki
          pengalaman lebih dari 40 tahun di sektor bisnis properti di seluruh
          Indonesia dan luar negeri. CENTURY 21 Indonesia adalah satu-satunya
          pemegang merek CENTURY 21 (Franchisor) dan memiliki 6.500 Marketing
          Associate yang tersebar di seluruh Indonesia. Perusahaan ini juga
          dikenal sebagai anggota Asosiasi Real Estate Broker Indonesia (AREBI).
        </p>
      </div>
    </div>
  );
};

export default C21Info;
