import React from 'react'
import C21Logo from "../../../../assets/svgs/c21-logo-white.svg";

function Header() {
  return (
    <div className='detail-listing-header'>
      <C21Logo />
    </div>
  )
}

export default Header