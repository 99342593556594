import React from 'react'

//Styles
import Styles from "./footer.module.scss"

function Footer() {
  return (
    <div className={Styles['wrapper']}>
      <div className={Styles['content']}>
        <p>© 2023 Century 21 Indonesia. All rights reserved.</p>
      </div>
    </div>
  )
}

export default Footer