import React from 'react'

// Styles
import Styles from './spinner.module.scss'

function Spinner() {
  return (
    <div className={Styles["loader"]}></div>
  )
}

export default Spinner