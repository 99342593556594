import React from 'react'

function KondisiRumah({ data }) {
  const { tahunDibangun, sertifikat, menghadap, kondisi } = data;

  if(!+tahunDibangun && !sertifikat && !menghadap && !kondisi) return null;
  return (
    <div className='kondisi-rumah'>
      <p className='header-kondisi-rumah'>Kondisi Rumah</p>
      {+tahunDibangun ? <div className='section-kondisi-rumah'>
        <span>Dibangun pada tahun</span>
        <span>{tahunDibangun}</span>
      </div> : null}
      {sertifikat ? <div className='section-kondisi-rumah'>
        <span>Sertifikat</span>
        <span>{sertifikat}</span>
      </div> : null}
      {menghadap ? <div className='section-kondisi-rumah'>
        <span>Properti menghadap</span>
        <span>{menghadap}</span>
      </div> : null}
      {kondisi ? <div className='section-kondisi-rumah'>
        <span>Kondisi</span>
        <span>{kondisi}</span>
      </div> : null}
    </div>
  )
}

export default KondisiRumah