import React from 'react'

import Style from './footer.module.scss'

import C21Logo from "assets/svgs/c21-logo-white.svg";

const Footer = () => {
  return (
    <div className={Style['wrapper']}><C21Logo /></div>
  )
}

export default Footer