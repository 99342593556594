import React, { useState, useMemo } from 'react'
import dynamic from 'next/dynamic'; // Import dynamic from Next.js

import _ from "lodash";
import Slider from "react-slick";

//Components
import Modal from "../../modal";

//Assets
import CloseIcon from "../../../../../assets/svgs/X-white.svg";
import ArrowRight from "../../../../../assets/svgs/slider-arrow-right.svg";
import ArrowLeft from "../../../../../assets/svgs/slider-arrow-left.svg";

import { useGetViewType } from "hooks/useGetViewType";

const DynamicVideoPlayer = dynamic(() => import('../../video-player'), {
  ssr: false, // Set ssr to false to disable server-side rendering for this component
});

function Image({ images, video }) {
  const { windowSize } = useGetViewType()

  const [openModal, setOpenModal] = useState(false)
  const [currentImage, setCurrentImage] = useState(0)

  const thumbnail = useMemo(() => {
    if (!images?.length) return "";

    return _.get(images[0], "image.thumbnail.url")
  }, [images])

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <ArrowRight />,
    prevArrow: <ArrowLeft />,
    afterChange: (e) => setCurrentImage(e)
  };

  const displayImages = useMemo(() => {
    if (!images?.length) return [];

    const num = windowSize[0] <= 500 ? 2 : 3
    return images.filter((_, idx) => idx <= num)
  }, [images])

  if (!images?.length && !video) return null;
  return (
    <div className='detail-listing-dekstop-image-wrapper'>
      <div className="thumbnails">
        <div className='main-thumbnail'>
          {!!video ? (
            <DynamicVideoPlayer videoUrl={video} />
          ) : (
            <img onClick={() => setOpenModal(true)} src={thumbnail} alt="image" className="main-image" />
          )}
        </div>
        <div className='other-thumbnail'>
          {displayImages?.length ? displayImages.map((img, key) => {
            if (!video && key === 0) return null;
            if (key === (displayImages.length - 1)) return (
              <div className="image" onClick={() => setOpenModal(true)} key={`other-thumbnail-${key}`}>
                <img src={img?.image?.url} alt="image" />
                <div className="lihat-foto-lainnya">
                  <p>Lihat foto lainnya +</p>
                </div>
              </div>
            )

            return (
              <div className="image" onClick={() => setOpenModal(true)} key={`other-thumbnail-${key}`}>
                <img src={img?.image?.url} alt="image" />
              </div>
            )
          }) : null
          }
        </div>
      </div>

      <Modal isOpen={openModal}>
        <div className='detail-listing-dekstop-images-modal-content'>
          <div className='detail-listing-modal-content'>
            <div className='tutup-btn-modal'>
              <div className='tutup-modal-btn' onClick={() => setOpenModal(false)}>
                <CloseIcon className="tutup-icon-svg" />
                <span>Tutup</span>
              </div>
            </div>
            <div className='detail-listing-image-slider'>
              <Slider {...settings} className="modal-images-body">
                {images?.length ? images.map((img, key) => {
                  return (
                    <div className='image-wrapper' key={`detail-listing-slider-${key}`}>
                      <img src={img?.image?.url} alt="..." />
                    </div>
                  )
                }) : null}
              </Slider>
            </div>
            <div className='detail-listing-pagination'>
              <p>{currentImage + 1} <span>dari</span> {images.length} <span>gambar</span></p>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  )
}

export default Image