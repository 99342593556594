import React, { useState, useEffect } from 'react'
import { useRouter } from 'next/router';

// Style
import Style from "./modal-leads.module.scss"

// API
import { submitLeadsForm } from '@services/detail-listing';

// Assets
import XIcon from "assets/svgs/x-black.svg"
import InfoIcon from "assets/svgs/info-black.svg"

// Components
import Spinner from "components/reuseables/Spinner"

const ModalLeads = ({
  full_name,
  onClose,
  phone_number,
  user_id,
  property_id,
  slug
}) => {
  const { query } = useRouter()

  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isReadyToRedirect, setIsReadyToRedirect] = useState(false);

  const handlePhone = (event) => {
    const { value } = event.target;

    if (/^0[0-9]*$/.test(value) || value === '') {
      // Input is valid (starts with 0 and contains only numbers)
      setPhone(value);
    }
  }


  const messageAgent = () => {
    const textWa = `Halo ${full_name},\n\ saya ingin info lebih lanjut tentang listing ${window.location.href}`;

    const url = `https://api.whatsapp.com/send?text=${encodeURIComponent(textWa)}&phone=${phone_number}`;

    window.location.href = url
  }

  const handleSubmit = async () => {
    try {
      if (!name || !phone) return;
      let payload = {
        name,
        phone: "+62" + phone.slice(1),
        user_id,
        property_id,
        slug
      }

      if (!!query.source) {
        payload.kind = query.source
      }

      setIsLoading(true)
      const response = await submitLeadsForm(payload)
      const status = response?.response?.status ?? 0;
      
      if (status >= 200 && status < 300) {
        setIsReadyToRedirect(true)
      }
    } catch (error) {
      console.log(error)
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    !!isReadyToRedirect && !isLoading && messageAgent()
  }, [isReadyToRedirect, isLoading])

  return (
    <div className={Style['wrapper']}>
      <div className={Style['content']}>
        <div className={Style['icon-wrapper']}>
          <XIcon onClick={onClose} className={Style['icon']} />
        </div>

        <p className={Style['header']}>Isi Informasi</p>
        <p className={Style['sub-header']}>Kami membutuhkan informasi kontak untuk dapat segera menghubungi Anda.</p>

        <div className={"mb-i"}>
          <p className={Style["input-title"]}>Nama Anda</p>
          <input
            placeholder='Masukkan nama'
            value={name}
            onChange={(e) => setName(e.target.value || "")}
            className={Style["input"]}
          />
        </div>

        <div className={"mb-c"}>
          <p className={Style["input-title"]}>Nomor WhatsApp</p>
          <input
            placeholder='Masukkan nomor WhatsApp'
            value={phone}
            onChange={handlePhone}
            className={Style["input"]}
          />
        </div>

        <div className={`${Style["info"]} mb-i`}>
          <InfoIcon className={`${Style["info-icon"]} mr-b`} />
          <p className={Style["info-txt"]}>Nomor yang Anda masukkan akan kami berikan kepada Marketing pemilik listing ini. Marketing yang bersangkutan akan segera menghubungi Anda di nomor ini.</p>
        </div>

        <div className={`${Style["divider"]} mb-e`} />

        <div>
          <button
            disabled={!name || !phone}
            className={`${Style["btn"]} ${Style["btn-submit"]} mb-e`}
            onClick={handleSubmit}
          >Kirim Info Saya</button>
          <button className={`${Style["btn"]} ${Style["btn-cancel"]} `}>Batal</button>
        </div>

        {isLoading && <div className={Style['spinner-wrapper']}>
          <Spinner />
        </div>}
      </div>
    </div>
  )
}

const ModalLeadsWrapper = ({ isOpen, ...props }) => {

  if (!isOpen) return null;
  return <ModalLeads {...props} />
}

export default ModalLeadsWrapper