import React from 'react'
import Head from 'next/head'
import { useGetViewType } from "hooks/useGetViewType";

import DetailListing20Mobile from './detail-listing-2.0-mobile'
import DetailListing20Dekstop from './detail-listing-2.0-desktop'

export default function index(props) {
  const { images, data, location } = props
  const { windowSize } = useGetViewType()
  let price = data
    ? data?.property?.price
      .toString()
      .split('')
      .reverse()
      .join('')
      .match(/\d{1,3}/g)
      .join('.')
      .split('')
      .reverse()
      .join('')
    : ``
  let currency = data
    ? data.property.currency.name === `IDR`
      ? `Rp`
      : `$`
    : ``
  let metaAgen = ` | Agen: ${data?.user?.full_name}`
  let metaDesc = data
    ? `${data?.property?.property_type?.name} ${data?.property?.property_category?.name} ${currency}${price} di ${location?.city?.name}${metaAgen}`
    : `One21: Asisten Virtual Untuk Agen Properti`

  const isMobileView = windowSize[0] <= 1068;

  return (
    <div>
      <Head>
        <title>{`${data ? data.property.title : `Listing`} | One21`}</title>
        <meta property="og:description" content={metaDesc} />
        <meta
          property="og:image"
          content={
            data
              ? data?.property?.summary?.property_images?.image?.thumbnail?.url ? data?.property?.summary?.property_images?.image?.thumbnail?.url : `https://s3.ap-southeast-1.amazonaws.com/production.assets.one21/assets/logo/icon-90efdb05b35f99324bb28b2d9827c138c2574cc8fafbeec6e37fdeea501fb6e6.png`
              : `https://s3.ap-southeast-1.amazonaws.com/production.assets.one21/assets/logo/icon-90efdb05b35f99324bb28b2d9827c138c2574cc8fafbeec6e37fdeea501fb6e6.png`
          }
        />
        <meta
          property="og:title"
          content={`${data ? data.property.title : `Listing`} | One21`}
        />
      </Head>

      {isMobileView ? 
        <DetailListing20Mobile data={data} images={images} location={location} /> : 
        <DetailListing20Dekstop data={data} images={images} location={location} />}
    </div>
  )
}
