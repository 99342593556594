import React from 'react';
import { Badge, Card, CardImg } from 'reactstrap';
import { Carousel } from 'react-responsive-carousel';
import Ionicon from 'react-ionicons';

export default function CarouselComponent({
  images,
  stateSlide,
  onOpenModal,
  onStateImage,
  selectedItem,
}) {
  return (
    <Card
      style={{
        border: 'none',
        borderRadius: 0,
        margin: '3.8rem auto 0px auto'
      }}
    >
      <div style={{ position: 'relative' }}>
        {images.length > 0 && <Badge
          style={{
            position: 'absolute',
            left: '.5rem',
            zIndex: '1',
            backgroundColor: '#000',
            opacity: 0.8,
            bottom: '.5rem',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <div style={{ float: 'left', padding: 10, fontSize: 12 }}>
            {stateSlide.current} / {stateSlide.total}
          </div>
        </Badge>}
        <Carousel
          showThumbs={false}
          showStatus={false}
          onClickItem={(index, item) => onOpenModal(index, item)}
          selectedItem={selectedItem}
          onChange={(current) => onStateImage(current)}
          infiniteLoop
          autoPlay
          swipeable
          emulateTouch
          showIndicators={false}
        >
          {images.length > 0 ?
            images.map((val, key) => (
              <div key={`car-${key}`}>
                <CardImg
                  top
                  style={{
                    height: 250,
                    width: '100%',
                    objectFit: 'cover',
                    borderRadius: 0,
                  }}
                  src={
                    val?.image?.thumbnail?.url ??
                    `https://s3.ap-southeast-1.amazonaws.com/staging-cdn.homebase.id/no-image.jpg`
                  }
                  alt='Listing image'
                />
              </div>
            )) : 
            <div>
              <CardImg
                top
                style={{
                  height: 250,
                  width: '100%',
                  objectFit: 'cover',
                  borderRadius: 0,
                }}
                src={
                  `https://s3.ap-southeast-1.amazonaws.com/staging-cdn.homebase.id/no-image.jpg`
                }
                alt='Listing image'
              />
            </div>
          }
        </Carousel>
      </div>
    </Card>
  );
}
