import React from 'react'

import GreenCheck from "../../../../assets/svgs/green-check.svg";

function FasilitasProperty({ data }) {
  const { tamanPribadi, telepon, kolam, AC, CCTV } = data

  if (!parseInt(telepon) && !tamanPribadi && !kolam) return null;
  return (
    <div className='fasilitas-property-dekstop'>
      <p className='header-fasilitas-property'>Fasilitas properti</p>

      <div className='fasilitas-property-content'>
        {parseInt(telepon) ? <div className='section-fasilitas-property'>
          <span>Line telepon</span>
          <GreenCheck className="green-check" />
        </div> : null}
        {tamanPribadi ? <div className='section-fasilitas-property'>
          <span>Taman pribadi</span>
          <GreenCheck className="green-check" />
        </div> : null}
        {kolam ? <div className='section-fasilitas-property'>
          <span>Kolam renang pribadi</span>
          <GreenCheck className="green-check" />
        </div> : null}
        {AC ? <div className='section-fasilitas-property'>
          <span>AC (Air Conditioner)</span>
          <GreenCheck className="green-check" />
        </div> : null}
        {CCTV ? <div className='section-fasilitas-property'>
          <span>CCTV</span>
          <GreenCheck className="green-check" />
        </div> : null}
      </div>
    </div>
  )
}

export default FasilitasProperty