import React from 'react'
import AWS from 'aws-sdk';
import { handleLogEvent } from "config/firebaseAnalytics";

const ASSETS_BUCKET = process.env.NEXT_PUBLIC_ASSETS_BUCKET
const AWS_ACCESS_KEY_ID = process.env.NEXT_PUBLIC_AWS_ACCESS_KEY_ID
const AWS_SECRET_ACCESS_KEY = process.env.NEXT_PUBLIC_AWS_SECRET_ACCESS_KEY
const AWS_REGION_BUCKET = process.env.NEXT_PUBLIC_AWS_REGION

const useS3 = (storage) => {
  const handleUpload = async (file, name) => {
    return new Promise((resolve, reject) => {

      if (!file) {
        alert('Please select a file to upload');
        return;
      }

      AWS.config.update({
        accessKeyId: AWS_ACCESS_KEY_ID,
        secretAccessKey: AWS_SECRET_ACCESS_KEY,
        region: AWS_REGION_BUCKET

      });

      // Configure AWS SDK
      const s3 = new AWS.S3();

      // Prepare parameters for S3 upload
      const params = {
        Bucket: ASSETS_BUCKET,
        Key: storage + name + ".png",
        Body: file,
        ACL: 'public-read', // Optional: set ACL permissions
        ContentType: 'image/png'
      };

      s3.upload(params, (err, data) => {
        if (err) {
          handleLogEvent("s3_error_upload", {
            message: JSON.stringify(err)
          })
          reject(err)
        } else {
          handleLogEvent("s3_success_upload", {
            location: data.Location
          })
          resolve(data.Location)
        }
      })
    })
  };

  return {
    handleUpload
  }
}

export default useS3