import React, { useMemo, useState } from 'react'
import dynamic from 'next/dynamic'; // Import dynamic from Next.js
import _ from "lodash";

//Components
import Modal from "../modal"

//Assets
import CloseIcon from "../../../../assets/svgs/X.svg";

const DynamicVideoPlayer = dynamic(() => import('../video-player'), {
  ssr: false, // Set ssr to false to disable server-side rendering for this component
});

function ListingImages({ images, videoUrl }) {
  const [openModal, setOpenModal] = useState(false)

  const thumbnail = useMemo(() => {
    if (!images?.length) return "";

    return _.get(images[0], "image.thumbnail.url")
  }, [images])

  const detailImages = useMemo(() => {
    if (!images?.length) return [];

    return images.map(img => img?.image?.url ?? "")
  }, [images])


  const displayImages = useMemo(() => {
    if (!images?.length) return [];

    return images.filter((_, idx) => idx <= 4)
  }, [images])

  if (!images?.length && !videoUrl) return null;
  return (
    <div className='listing-images'>
      <div className="thumbnails">
        <div className='main-thumbnail' >
          {!!videoUrl ? (
            <DynamicVideoPlayer videoUrl={videoUrl} />
          ) : (
            <img onClick={() => setOpenModal(true)} src={thumbnail} alt="image" className="main-image" />
          )}
        </div>
        <div className='other-thumbnail'>
          {displayImages?.length ? displayImages.map((img, key) => {
            if (key === 0) return null;
            if (key === (displayImages.length - 1)) return (
              <div className="image" onClick={() => setOpenModal(true)}>
                <img src={img?.image?.url} alt="image" />
                <div className="lihat-foto-lainnya">
                  <p>Lihat foto lainnya +</p>
                </div>
              </div>
            )

            return (
              <div className="image" onClick={() => setOpenModal(true)}>
                <img src={img?.image?.url} alt="image" />
              </div>
            )
          }) : null
          }
        </div>
      </div>

      <Modal isOpen={openModal}>
        <div className='images-modal-content'>
          <div className='modal-images-header'>
            <p>Tampak properti</p>
            <CloseIcon onClick={() => setOpenModal(false)} className="modal-image-exit-icon" />
          </div>
          <div className='modal-images-body'>
            {detailImages?.length ? (
              detailImages.map((detail, key) => {
                return (
                  <div className='images-wrapper' key={`imagelist-${key}`}>
                    <img src={detail} alt="detail-image" />
                  </div>
                )
              })
            ) : null}
          </div>
        </div>
      </Modal>

      {/* <Modal isOpen={isOpenModalVideo}>
        <div className='video-modal-content'>
          <div className='modal-images-header'>
            <CloseIcon onClick={() => setIsOpenModalVideo(false)} className="modal-image-exit-icon" />
          </div>

          <div>
            <DynamicVideoPlayer isModal={true} videoUrl={videoUrl} />
          </div>
        </div>
      </Modal> */}
    </div>
  )
}

export default ListingImages