import React, { useLayoutEffect, useState } from 'react';

export const useGetViewType = () => {
  const [loading, setLoading] = useState([0, 0]);
  const [view, setView] = useState('');
  const [windowSize, setWindowsSize] = useState([0, 0]);

  useLayoutEffect(() => {
    if(!window) {
      return
    }
    const {
      document: { body },
    } = window;

    const viewSetter = () => {
      if (body.clientWidth < 500) {
        setView('mobile');
        return;
      }

      if (body.clientWidth > 500) {
        setView('desktop');
        return;
      }
    }

    const handler = () => {
      setWindowsSize([body.clientWidth, body.clientHeight]);
      viewSetter();
    };

    window.addEventListener('resize', handler);
    handler();

    return () => {
      window.removeEventListener('resize', handler);
      setView('desktop');
      setWindowsSize([0, 0])
    };
  }, []);

  return {
    view,
    isMobileView: view === 'mobile',
    isDesktopView: view === 'desktop',
    windowSize,
  };
};
