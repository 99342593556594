import React from 'react'

function KondisiProperty({ data }) {
  const { tahunDibangun, sertifikat, menghadap, kondisi } = data;

  if (!+tahunDibangun && !sertifikat && !menghadap && !kondisi) return null;
  return (
    <div className='kondisi-property'>
      <p className='header-kondisi-property'>Kondisi properti</p>

      <div className='kondisi-content'>
        {+tahunDibangun ? <div className='section-kondisi-property'>
          <span>Dibangun pada tahun</span>
          <span>{tahunDibangun}</span>
        </div> : null}
        {sertifikat ? <div className='section-kondisi-property'>
          <span>Sertifikat</span>
          <span>{sertifikat}</span>
        </div> : null}
        {menghadap ? <div className='section-kondisi-property'>
          <span>Properti menghadap</span>
          <span>{menghadap}</span>
        </div> : null}
        {kondisi ? <div className='section-kondisi-property'>
          <span>Kondisi</span>
          <span>{kondisi}</span>
        </div> : null}
      </div>
    </div>
  )
}

export default KondisiProperty