import React from "react"

const RASIO = {
  "portrait": [
    {
      "decimals": 0.8,
      "rasio": "4:5"
    },
    {
      "decimals": 0.75,
      "rasio": "3:4"
    },
    {
      "decimals": 0.563,
      "rasio": "9:16"
    }
  ],
  "landscape": [
    {
      "decimals": 1.25,
      "rasio": "5:4"
    },
    {
      "decimals": 1.333,
      "rasio": "4:3"
    },
    {
      "decimals": 1.778,
      "rasio": "16:9"
    }
  ]
}

const useRasio = () => {
  const getRatio = (orientation, imgDecimals) => {
    const ratio = RASIO[orientation]

    const ratioDiff = ratio.map((r) => ({ ...r, diff: Math.abs(r.decimals - imgDecimals) }))

    const lowest = ratioDiff.reduce((min, current) => (current.diff < min.diff ? current : min), ratioDiff[0])

    return lowest.rasio
  }

  return getRatio
}

export default useRasio