import { useRef, useState } from 'react'
import html2canvas from 'html2canvas';

import useS3 from 'hooks/useS3';


const dataURLtoFile = (dataUrl, filename) => {
  const arr = dataUrl.split(',');
  const mime = arr[0].match(/:(.*?);/)[1];
  const bstr = atob(arr[1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new File([u8arr], filename, { type: mime });
};

const useHtmlToImage = (s3StorageLocation) => {
  const cardRef = useRef(null);
  const { handleUpload } = useS3(s3StorageLocation);

  const [imagePreview, setImagePreview] = useState(null)
  const [isLoading, setIsLoading] = useState(true)
  const [isSuccess, setIsSuccess] = useState(false)

  const convertToPdf = async (name) => {
    try {
      setIsLoading(true)

      const input = cardRef.current;

      const canvasOptions = {
        useCORS: true,
        allowTaint: true,
        backgroundColor: null,
      };
     
      const canvas = await html2canvas(input, canvasOptions)
      const imgData = canvas.toDataURL('image/png');
      const file = dataURLtoFile(imgData, `${name}.png`);
      const result = await handleUpload(file, `${name}`);
      setImagePreview(result)
      setIsSuccess(true)
    } catch (error) {
      console.log(error)
      setIsSuccess(false)
    } finally {
      setIsLoading(false)
    }
  };

  return {
    cardRef,
    convertToPdf,
    isLoading,
    imagePreview,
    isSuccess
  };
}

export default useHtmlToImage