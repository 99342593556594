import React, { Component } from 'react';
import Link from 'next/link';
import Ionicon from 'react-ionicons';
import { Button } from 'reactstrap';

class Header extends Component {
  render() {
    const { data, isLoggedIn } = this.props;
    return (
      <nav
        className="navbar navbar-light bg-white col-12 col-sm-7 col-md-5 col-lg-4 col-xl-4"
        style={{
          position: 'fixed',
          top: 0,
          zIndex: 2,
          display: 'flex',
          boxShadow: '0px 3px 8px -5px #CCC, 0px -3px 8px -5px #CCC',
          alignItems: 'center',
        }}
      >
        <div>
          <Link href={`/`}>
            <a className="navbar-brand">
              <img
                src="../../static/images/icons/one21/one21logo.png"
                style={{ height: '30px' }}
              />
            </a>
          </Link>
        </div>
        <div>
          {data ? (
            <small>1 / {data.length}</small>
          ) : (
            <small style={{ color: 'transparent' }}>{`1 / 1`}</small>
          )}
        </div>
      </nav>
    );
  }
}

export default Header;
