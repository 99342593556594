const parsePhoneNumber = (phoneNumber) => {
  if (phoneNumber.startsWith("+62")) {
    phoneNumber = phoneNumber.replace("+62", "");
    let newFormat = phoneNumber
      .split("")
      .map((char, i) => {
        if ((i + 1) % 4 === 0) {
          return char + " - ";
        } else {
          return char;
        }
      })
      .join("")
    
    return "+62 " + newFormat;
  }

  return phoneNumber.split("").map((char, i) => {
    if (i + 1 % 4 === 0) {
      return char + "-";
    } else {
      return char;
    }
  }).join(" ");
}

export default parsePhoneNumber;