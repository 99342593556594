import { useRef, useState } from 'react'
import html2canvas from 'html2canvas';
import QRCode from 'qrcode';

import useS3 from 'hooks/useS3';

const useDownloadLeaderboard = () => {
  const cardRef = useRef(null);

  const { handleUpload } = useS3("storages/store/leaderboard/");

  const [isConverting, setIsConverting] = useState(false)
  const [qrCodeUrl, setQrCodeUrl] = useState("")
  
  const convertToPdf = async (id, name, resolve) => {
    try {
      setIsConverting(true)

      const input = cardRef.current;

      const scale = 1;
      const canvasOptions = {
        scale: scale,
        useCORS: true,
        allowTaint: true,
        backgroundColor: null,
      };
     
      const canvas = await html2canvas(input, canvasOptions)
      const imgData = canvas.toDataURL('image/png');
      const file = dataURLtoFile(imgData, `${id}-${name}.png`);
      const result = await handleUpload(file, `${id}-${name}`);
      resolve(result)
    } catch (error) {
      console.log(error)
    } finally {
      setIsConverting(false)
    }
  };

  const dataURLtoFile = (dataUrl, filename) => {
    const arr = dataUrl.split(',');
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  };

  const generateQRCode = async (url) => {
    try {
      const result = await QRCode.toDataURL(url);
      setQrCodeUrl(result);
    } catch (err) {
      console.error(err);
    }
  };

  return {
    cardRef,
    convertToPdf,
    isConverting,
    generateQRCode,
    qrCodeUrl,
  };
}

export default useDownloadLeaderboard