import React, { useState, useEffect, useRef, useCallback, useMemo } from "react";
import { getListings } from '@services/marketing-profile';
import lodash from 'lodash';

//Styles
import Styles from './modal-listing.module.scss'

//Components
import ListingCard from "@components/reuseables/ListingCard"
import Spinner from "@components/reuseables/Spinner"
import Search from "@assets/svgs/search.svg";

//Assets
import Arrow from '@assets/svgs/arrow-up-left.svg'
import ArrowUp from '@assets/svgs/arrow-up.svg'

// Hookes
import { useGetViewType } from "hooks/useGetViewType";
import useEnterKey from "hooks/useEnterKey";

const PROPERTY_TYPE = {
  Jual: 1,
  Sewa: 2,
}
const ModalListing = ({ onClose, marketingId, name, companyId }) => {
  const { windowSize } = useGetViewType();
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState("");
  const refListing = useRef(null)

  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(false)
  const [data, setData] = useState([])
  const [hasMore, setHasMore] = useState(false)
  const [propertyType, setPropertyType] = useState(null);

  const is820 = windowSize[0] <= 821;

  const fetchListing = async (_page) => {
    try {
      setLoading(true);
      setPage(_page)
      let params = `?page=${_page}`;

      if (companyId) {
        params += `&company_id=${companyId}`
      } else {
        params += `&user_id=${marketingId}`
      }
      
      if (search) {
        params += `&keywords=${search || ""}`
      }
      if (propertyType) {
        params += `&property_type_id=${propertyType}`
      }

      const res = await getListings(params);
      setHasMore(res.data.length > 0)
      setData(val => [...val, ...res.data]);

    } catch (error) {
      setError(true)
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  const debouncedFetchApi = useCallback(lodash.debounce(async (val) => {
    try {
      setLoading(true);
      setPage(1)
      let params = `?user_id=${marketingId}&page=${1}`;

      if (companyId) {
        params += `&company_id=${companyId}`
      }
      if (val) {
        params += `&keywords=${val || ""}`
      }
      if (propertyType) {
        params += `&property_type_id=${propertyType}`
      }

      const res = await getListings(params);
      setHasMore(res.data.length > 0)
      setData(res.data);

    } catch (error) {
      setError(true)
      console.log(error);
    } finally {
      setLoading(false);
    }
  }, 1500), [])

  useEffect(() => {
    fetchListing(1)
  }, [])

  useEffect(() => {
    if (propertyType) {
      setData([])
      fetchListing(1)
    }
  }, [propertyType])


  // Infinitescroll Observer
  const observer = useRef()
  const lastBookElementRef = useCallback(node => {
    if (loading) return
    if (observer.current) observer.current.disconnect()
    observer.current = new IntersectionObserver(entries => {
      if (entries[0].isIntersecting && hasMore) {
        fetchListing(page + 1)
      }
    })
    if (node) observer.current.observe(node)
  }, [loading, hasMore])

  const mappedListings = useMemo(() => {
    if (!data.length) return [];

    return data.map(i => ({
      image: i?.summary?.property_images?.image?.url ?? "",
      price: i?.price ? i?.price.toLocaleString("id-ID") : 0,
      title: i?.title ?? "",
      area: i?.summary?.property_locations?.[0]?.area?.name ?? "",
      city: i?.summary?.property_locations?.[0]?.city?.name ?? "",
      kamarTidur: lodash.toNumber(i?.bedroom) ?? 0,
      kamarTidurTambahan: lodash.toNumber(i?.additional_bedroom) ?? 0,
      kamarMandi: lodash.toNumber(i?.bathroom) ?? 0,
      kamarMandiTambahan: lodash.toNumber(i?.additional_bathroom) ?? 0,
      luasTanah: lodash.toNumber(i?.surface_area) ?? 0,
      slug: i?.slug ?? "",
      category: i?.property_category?.name ?? "",
    }))
  }, [data, page, search, propertyType, companyId, marketingId])

  function handleSearch(e) {
    setSearch(e.target.value)
    debouncedFetchApi(e.target.value)
  }

  const scrollToRef = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });

    // console.log(refListing.current);
    // refListing.current.scrollIntoView({ behavior: 'smooth' });
  }

  const triggerSearch = () => {
    if (loading) return;


    setLoading(true)
    setData([])
    debouncedFetchApi.cancel()
    fetchListing(1)
  }

  useEnterKey(() => {
    triggerSearch();
  })

  useEffect(() => {
    document.body.style.overflow = 'hidden';
    return () => {
      document.body.style.overflow = 'unset';
    }
  }, [])

  return (
    <div className={Styles['wrapper']} ref={refListing}>
      <div className={Styles['content']}>
        <div className={Styles['wrapper-marketing-name']}>
          <p className={Styles['marketing-name']}>Listing {name}</p>
          <button onClick={onClose} className={Styles['btn']}>{is820 ? <Arrow /> : <span>Kembai ke Beranda</span>}</button>
        </div>
        <div className={Styles['wrapper-search-and-type']}>
          <div className={Styles['search']}>
            <input type="text" placeholder="Cari listing di sini..." value={search} onChange={handleSearch} />
            <div className={Styles['icon']}>
              <Search onClick={triggerSearch} />
            </div>
          </div>
          <div className={Styles['type']}>
            <span className={`${!!(propertyType == PROPERTY_TYPE.Jual) && Styles['active']}`} onClick={() => !loading && setPropertyType(PROPERTY_TYPE.Jual)}>Jual</span>
            <span className={`${!!(propertyType == PROPERTY_TYPE.Sewa) && Styles['active']}`} onClick={() => !loading && setPropertyType(PROPERTY_TYPE.Sewa)}>Sewa</span>
          </div>
        </div>

        {(loading && page) === 1 ?
          <div className={Styles['wrapper-scroll']}>
            {loading && <Spinner />}
          </div>
          :
          <div className={Styles['wrapper-listings']}>
            {
              !!mappedListings.length && mappedListings.map((item, index) => {
                if (mappedListings.length === index + 1) {
                  return <div ref={lastBookElementRef} key={index}><ListingCard marketingId={marketingId} item={item} /></div>
                }

                return (
                  <ListingCard marketingId={marketingId} key={index} item={item} />
                )
              })
            }
          </div>
        }

        <div className={Styles['wrapper-scroll']}>
          {(page !== 1 && loading) && <Spinner />}
        </div>

        {/* <div className={Styles['floating-button']}
          onClick={scrollToRef}
        >
          <ArrowUp
            width={30}
            height={30}
          />
        </div> */}
      </div>
    </div>
  )
}

export default ModalListing