import React from 'react'

//Styles
import Styles from './profile.module.scss'

//Assets
import Dot from "@assets/svgs/dot-grey.svg";
import Telepon from "@assets/svgs/telepon.svg";
import Wa from "@assets/svgs/whatsapp.svg";
import Arrow from "@assets/svgs/arrow-down-white.svg";
const isBrowser = () => typeof window !== 'undefined';

const Profile = ({ data = {}, scrollToRef }) => {
  const messageAgent = () => {
    const textWa = `Halo ${name},\n\saya ingin bertanya tentang listing ${window.location.href}\n\n_Pesan ini dikirim via ONE21._`;

    const url = `https://api.whatsapp.com/send?text=${encodeURI(textWa)}&phone=${data.phone}`;

    window.location.href = url
  }

  const profileName = React.useMemo(() => {
    if(data.name) {
      const name = data?.name?.split(' ') ?? ""
      const restName = name.slice(2 , name.length)
      const singkatan = restName.map((item) => `${item[0]}.`).join(" ") || ""
      return `${name[0] ? name[0] : ""} ${name[1] ? name[1] : ""} ${singkatan || ""}`
    }
    return ""
  }, [data.name])

  return (
    <div className={Styles['wrapper']}>
      <div className={Styles['content']}>
        <div className={Styles['image']}>
          <img src={data.photo} alt="Avatar" />
        </div>

        <div className={Styles['info']}>
          <p className={Styles['text-welcome']}>Halo, saya</p>
          <p className={Styles['name']}>{profileName}</p>
          <p className={Styles['career-level']}>{data.careerLevel}</p>
          <p className={Styles['office']}>Century 21 {data.office}</p>
          
          <div className={Styles['contacts']}>
            <div className={Styles['lihat-properti']} onClick={scrollToRef}>
              <span>Lihat properti</span>
              <Arrow />
            </div>
            <div className={Styles['contact']} onClick={isBrowser && messageAgent}>
              <span className={Styles['wa']}>Hubungi saya</span>
              <Wa className={Styles['icon']} />
            </div>
          </div>
        </div>

      </div>
    </div>
  )
}

export default Profile